import React from "react";
const TickSuccess = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
        >
            <path
                d="M33.9998 67.3333C15.5898 67.3333 0.666504 52.41 0.666504 34C0.666504 15.59 15.5898 0.666626 33.9998 0.666626C52.4098 0.666626 67.3332 15.59 67.3332 34C67.3332 52.41 52.4098 67.3333 33.9998 67.3333ZM30.6765 47.3333L54.2432 23.7633L49.5298 19.05L30.6765 37.9066L21.2465 28.4766L16.5332 33.19L30.6765 47.3333Z"
                fill="#00932F"
            />
        </svg>
    );
};

export default TickSuccess;
