/* eslint-disable react/no-unknown-property */
import React from "react";
const SendMailSuccess = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="161"
            height="161"
            viewBox="0 0 161 161"
            fill="none"
        >
            <circle
                cx="80.8926"
                cy="80.4672"
                r="77.7004"
                fill="#161616"
                stroke="#161616"
                stroke-width="4"
            />
            <path
                d="M154.7 78.5747C154.7 120.383 120.808 154.275 79 154.275C37.1918 154.275 3.29956 120.383 3.29956 78.5747C3.29956 36.7665 37.1918 2.87427 79 2.87427C120.808 2.87427 154.7 36.7665 154.7 78.5747Z"
                fill="#FFC400"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M157.575 78.5747C157.575 121.97 122.396 157.149 79 157.149C35.6044 157.149 0.425293 121.97 0.425293 78.5747C0.425293 35.1791 35.6044 0 79 0C122.396 0 157.575 35.1791 157.575 78.5747ZM79 154.275C120.808 154.275 154.7 120.383 154.7 78.5747C154.7 36.7665 120.808 2.87425 79 2.87425C37.1918 2.87425 3.29954 36.7665 3.29954 78.5747C3.29954 120.383 37.1918 154.275 79 154.275Z"
                fill="#161616"
            />
            <mask
                id="mask0_6881_7888"
                style={{maskType:"alpha"}}
                maskUnits="userSpaceOnUse"
                x="3"
                y="2"
                width="152"
                height="153"
            >
                <path
                    d="M154.7 78.5747C154.7 120.383 120.808 154.275 78.9998 154.275C37.1916 154.275 3.29932 120.383 3.29932 78.5747C3.29932 36.7665 37.1916 2.87427 78.9998 2.87427C120.808 2.87427 154.7 36.7665 154.7 78.5747Z"
                    fill="#FFC400"
                />
            </mask>
            <g mask="url(#mask0_6881_7888)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M132.244 155.023V86.0421C132.244 85.7327 132.119 85.4364 131.897 85.221L114.515 68.366V63.5861L134.286 82.7575C135.174 83.6192 135.676 84.8043 135.676 86.0421V155.023C135.676 157.549 133.627 159.598 131.101 159.598H27.0128C24.4859 159.598 22.4375 157.549 22.4375 155.023V86.0421C22.4375 84.8043 22.9391 83.6192 23.8277 82.7575L44.2595 62.9449V67.7248L26.2165 85.221C25.9944 85.4364 25.869 85.7327 25.869 86.0421V155.023C25.869 155.654 26.3811 156.166 27.0128 156.166H131.101C131.732 156.166 132.244 155.654 132.244 155.023ZM96.1604 45.7876L82.2418 32.2907C80.4671 30.5698 77.6463 30.5698 75.8716 32.2907L61.953 45.7876H66.8822L78.2604 34.7541C78.7041 34.3239 79.4093 34.3239 79.853 34.7541L91.2312 45.7876H96.1604Z"
                    fill="#161616"
                />
                <path
                    d="M47.6017 47.5032H111.656C112.287 47.5032 112.8 48.0153 112.8 48.647V103.55L116.231 100.119V48.647C116.231 46.1201 114.183 44.0717 111.656 44.0717H47.6017C45.0748 44.0717 43.0264 46.1201 43.0264 48.647V101.263L46.4578 103.55V48.647C46.4578 48.0153 46.9699 47.5032 47.6017 47.5032Z"
                    fill="#161616"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M80.2003 101.263C90.6236 101.263 99.0734 92.8134 99.0734 82.39C99.0734 71.9667 90.6236 63.517 80.2003 63.517C69.777 63.517 61.3272 71.9667 61.3272 82.39C61.3272 92.8134 69.777 101.263 80.2003 101.263ZM80.2003 104.695C92.5187 104.695 102.505 94.7085 102.505 82.39C102.505 70.0716 92.5187 60.0855 80.2003 60.0855C67.8818 60.0855 57.8958 70.0716 57.8958 82.39C57.8958 94.7085 67.8818 104.695 80.2003 104.695Z"
                    fill="#161616"
                />
                <path
                    d="M99.0734 82.39C99.0734 92.8134 90.6236 101.263 80.2003 101.263C69.777 101.263 61.3272 92.8134 61.3272 82.39C61.3272 71.9667 69.777 63.517 80.2003 63.517C90.6236 63.517 99.0734 71.9667 99.0734 82.39Z"
                    fill="#161616"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M91.1354 73.4062C91.8055 74.0762 91.8055 75.1626 91.1354 75.8326L75.2887 91.6794L68.5067 84.8973C67.8367 84.2273 67.8367 83.141 68.5067 82.4709C69.1768 81.8009 70.2631 81.8009 70.9331 82.4709L75.2887 86.8265L88.709 73.4062C89.3791 72.7361 90.4654 72.7361 91.1354 73.4062Z"
                    fill="#FFC400"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M78.156 131.289C79.2663 132.353 81.0192 132.348 82.1239 131.279L133.289 81.7291L135.676 84.1942L84.511 133.744C82.0807 136.097 78.2243 136.107 75.7817 133.767L24.0606 84.2004L26.4348 81.7229L78.156 131.289Z"
                    fill="#161616"
                />
                <path
                    d="M23.5818 155.921L25.5966 158.699L69.7703 126.657L67.4827 124.077L23.5818 155.921Z"
                    fill="#161616"
                />
                <path
                    d="M133.382 158.694L135.41 155.926L91.5029 123.742L89.2153 126.32L133.382 158.694Z"
                    fill="#161616"
                />
            </g>
        </svg>
    );
};

export default SendMailSuccess;
