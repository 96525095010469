import http, { BaseGameServerAPI } from "./base";
export interface ISwitchMachine {
  orgId: string;
  newMachineId: string;
  oldMachineId: string;
  appId: number;
}

export interface IAddMachine {
  orgId: string;
  machineId: string;
  appId: number;
}
export interface IDeleteMachine {
  orgId: string;
  deleteValue: Array<IAddMachine['machineId']>;
}
export interface IMachineMembership {
  orgId: string;
  search?: string;
  pagination?: {
    page: number,
    perPage: number
  };
}

export const errorAddMachineCode = {
  PRODUCT_LIMIT_NUMBER_CODE: 1013,
  PRODUCT_EXIST_CODE: 1015,
  PRODUCT_NOT_FOUND_CODE: 1099,
  MACHINE_NOT_FOUND: 'machine_not_found',
  MACHINE_ALREADY_EXISTS: 'membership_already_exists',
}

export class MachineAPI extends BaseGameServerAPI {
  constructor() {
    super();
  }

  public async switchMembershipMachine({
    orgId,
    newMachineId,
    oldMachineId,
    appId,
  }: ISwitchMachine) {
    try {
      const res = await http.post("/switch_machine_for_membership", {
        orgId,
        newMachineId,
        oldMachineId,
        appId,
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  public async getListMachineMembership({
    orgId,
    search,
    pagination,
  }: IMachineMembership) {
    try {
      const res = await http.post("/get_list_machines_membership", {
        orgId,
        search,
        pagination,
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  public async addMembershipMachine({
    orgId,
    machineId,
    appId,
  }: IAddMachine) {
    try {
      const res = await http.post("/add_machine_for_membership", {
        orgId,
        machineId,
        appId,
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  public async deleteMembershipMachine({
    orgId,
    deleteValue,
  }: IDeleteMachine) {
    try {
      const res = await http.post("/delete_member_out_membership", {
        orgId,
        deleteValue,
        deleteField: 'machine_id',
      });
      return res;
    } catch (error) {
      throw error;
    }
  }
}
