import React from "react";
import style from "./Header.module.scss";
import InputSearch from "Components/base/InputSearch";
import InviteButton from "Components/base/Button/InviteButton";
import VerticalTabs from "Components/base/TabsLink/VerticalTabs";
import { CONSTANTS } from "consts";
import { useParams } from "react-router-dom";
import { MEMBERSHIP_STATUS } from "consts/statusMembership";
import {IMembershipCardDesktop} from '../MembershipCardDesktop'

interface IHeader {
    handleClickInviteMemberButton: any
    handleSearch?: any
    currentMembership?: any
    modeScreen?: IMembershipCardDesktop['modeScreen']
}

const Header = ({ handleClickInviteMemberButton, handleSearch, currentMembership, modeScreen }: any) => {
    console.log({currentMembership, modeScreen});
    const params = useParams();
    const dataTab = [
        {
            label: "Family Member",
             //@ts-ignore
            link: `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_INVITE_LIST}`.replace(':id', params?.id),
        },
    ];
    return (
        <div className={style["Header"]}>
            <div className={style['searchHeader']}>
                <div className={style["subTitle"]}>Member List</div>
                <div className={style["toolbar"]}>
                    <div className={style["search"]}>
                        <InputSearch
                            placeholder={"Search by email or user name"}
                            handleSearch={handleSearch}
                        />
                    </div>
                    {(currentMembership?.status ===
                            MEMBERSHIP_STATUS.activated &&
                            modeScreen !== "family") &&
                        <InviteButton onClick={handleClickInviteMemberButton} />
                    }
                </div>
            </div>
            <div className={style["membershipTab"]}>
                <VerticalTabs
                    orientation={"horizontal"}
                    dataTab={dataTab}
                    tabName={"profileTab"}
                />
            </div>
        </div>
    );
};

export default Header;
