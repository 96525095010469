import { useGetGameUserInfoQuery } from "services/crmServer";
import useGetCrmSessionHook from "./CrmSessionHook";
import { useEffect } from "react";

const useTimezoneHook = () => {
    const { crmToken } = useGetCrmSessionHook()
    const {
        data: GameUserInfoData,
        // error: GameUserInfoError,
        refetch,
        // loading,
    } = useGetGameUserInfoQuery({
        context: {
            clientName: "crm-server",
            headers: {
                Authorization: "Bearer " + crmToken
            }
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (crmToken) {
            refetch()
        }
    }, [crmToken])

    return {timeZoneHook: GameUserInfoData?.getGameUserInfo?.timezone, refetchTimezone: refetch};
};
export default useTimezoneHook;