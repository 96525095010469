import React from 'react';
import style from './AddMachineCard.module.scss'
import PrimaryButton from 'Components/base/Button/PrimaryButton';
import PlusPath from './Plus.png'

interface AddMachineCardProps {
    onClick?: any
}

const AddMachineCard = ({onClick}: AddMachineCardProps) => {
    
    return (
        <div className={style['AddMachineCard']} onClick={onClick}>
            <div className={style['machineInfoBlock']}>
                <div className={style['logo']}><img src={PlusPath} /></div>
                <div className={style['machineInfo']}>
                    <div className={style['info2']}>Add New PRODUCT</div>
                </div>
            </div>
        </div>
    )
}

export default AddMachineCard;