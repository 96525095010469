import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const UnderlineButton: React.FunctionComponent<{ onClick: Function, title: string }> = ({ onClick, title }) => {
    return (
        <Box textAlign="right">
            <Typography
                variant="body1"
                sx={{
                    marginTop: "32px",
                    textTransform: "uppercase",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "18.3px",
                    textDecoration: "underline",
                    cursor: "pointer",
                    textUnderlineOffset: "4px",
                }}
                onClick={onClick.bind(null)}
            >
                {title}     
            </Typography>
        </Box>
    )
}

export default UnderlineButton;