import * as React from 'react';
import UserApp from 'Screens/User';
export default class AppMain extends React.Component<any, any> {
  componentDidMount() {
    if ("serviceWorker" in navigator) {
      // window.addEventListener("load", function () {
      //   navigator.serviceWorker.register("/sw.js").then(
      //     function (registration) {
      //       console.log("Service Worker registration successful with scope: ", registration.scope);
      //     },
      //     function (err) {
      //       console.log("Service Worker registration failed: ", err);
      //     }
      //   );
      //   navigator.serviceWorker.ready.then(() => {
      //     console.log(
      //       'This web app is being served cache-first by a service ' +
      //       'worker. To learn more, visit https://bit.ly/CRA-PWA'
      //     );
      //   });
      // });
      let deferredPrompt: any;

      window.addEventListener('beforeinstallprompt', (e) => {
        deferredPrompt = e;
      });

      const installApp: any = document.getElementById('installApp');
      if (installApp) {
        installApp.addEventListener('click', async () => {
          if (deferredPrompt !== null) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
              deferredPrompt = null;
            }
          }
        });
      }

      // let deferredPrompt: any;
      // const addBtn = document.querySelector('.add-button') as HTMLElement;
      // if (addBtn) {
      //   addBtn.style.display = 'none';
      // }

      // window.addEventListener('beforeinstallprompt', (e) => {
      //   // Prevent Chrome 67 and earlier from automatically showing the prompt
      //   e.preventDefault();
      //   // Stash the event so it can be triggered later.
      //   deferredPrompt = e;
      //   addBtn.style.display = 'block';

      //   addBtn.addEventListener('click', (e) => {
      //     // hide our user interface that shows our A2HS button
      //     addBtn.style.display = 'none';
      //     // Show the prompt
      //     deferredPrompt.prompt();
      //     // Wait for the user to respond to the prompt
      //     deferredPrompt.userChoice.then((choiceResult: any) => {
      //       if (choiceResult.outcome === 'accepted') {
      //         console.log('User accepted the A2HS prompt');
      //       } else {
      //         console.log('User dismissed the A2HS prompt');
      //       }
      //       deferredPrompt = null;
      //     });
      //   });
      // });
    }
  }
  render() {
    return <UserApp />
  }
}
