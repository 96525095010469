import React, { FunctionComponent } from 'react';

import './style.scss';

export const AvironLoading: FunctionComponent = ({ }) => {
  // const hideLoading = () => {
  //   setTimeout(() => {
  //     setShowAvironLoading(false);
  //   }, 800);
  // };
  // const { showAvironLoading, setShowAvironLoading } = useContext(AppContext);
  // return showAvironLoading ? (
  return (
      <div className="aviron-loading">
          <div className="center-container">
              <div className="loader pos-rel" />
          </div>
      </div>
  );
  // ) : (
  //   <></>
  // );
};

