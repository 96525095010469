import React, { ReactChildren } from "react";
import style from "./style.module.scss";
import PlusIcon from "./PlusIcon";

interface InviteButtonProps {
    text?: string;
    onClick?: any;
    className?: string | any
}
const PrimaryButton = ({
    text,
    onClick,
    className
}: InviteButtonProps) => {
    return (
        <button
            className={style["PrimaryButton"] + " PrimaryButton" + ` ${className}`}
            onClick={onClick}
        >
            {text && <div className={style["text"]}>{text}</div>}
        </button>
    );
};

export default PrimaryButton;
