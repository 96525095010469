import React, { useState } from "react";
import style from "./Header.module.scss";
import InviteButton from "Components/base/Button/InviteButton";
import VerticalTabs from "Components/base/TabsLink/VerticalTabs";
import { CONSTANTS } from "consts";
import { useParams } from "react-router-dom";
import { ReactComponent as SearchIcon } from "Components/Icons/SearchIcon.svg";
import InputSearch from "Components/base/InputSearchMobile";
import { MEMBERSHIP_STATUS } from "consts/statusMembership";

interface IHeader {
    handleClickInviteMemberButton: any;
    handleSearch?: any;
    currentMembership?: any;
    modeScreen?: "family" | "invite";
}

const Header = ({
    handleClickInviteMemberButton,
    handleSearch,
    currentMembership,
    modeScreen,
}: any) => {
    console.log({currentMembership, modeScreen});
    const params = useParams();
    const [openSearchInput, setOpenSearchInput] = useState(false);
    const dataTab = [
        {
            label: "Family Member",
            link: `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_INVITE_LIST}`.replace(
                ":id",
                //@ts-ignore
                params?.id
            ),
        },
    ];
    const handleClickIconSearch = () => {
        setOpenSearchInput(true);
    };
    const handleClickCloseSearch = () => {
        setOpenSearchInput(false);
        handleSearch("");
    };
    return (
        <div className={style["Header"]}>
            {openSearchInput ? (
                <div className={style["searchInput"]}>
                    <InputSearch
                        placeholder={"Search by email or user name"}
                        handleSearch={handleSearch}
                        handleClickClose={handleClickCloseSearch}
                    />
                </div>
            ) : (
                <div className={style["searchHeader"]}>
                    <div className={style["subTitle"]}>Member List</div>
                    <div className={style["toolbar"]}>
                        <div
                            className={style["search"]}
                            onClick={handleClickIconSearch}
                        >
                            <SearchIcon />
                        </div>
                        {(currentMembership?.status ===
                            MEMBERSHIP_STATUS.activated &&
                            modeScreen !== "family") && 
                            (
                                <InviteButton
                                    onClick={handleClickInviteMemberButton}
                                    text=""
                                />
                            )}
                    </div>
                </div>
            )}
            <div className={style["membershipTab"]}>
                <VerticalTabs
                    orientation={"horizontal"}
                    dataTab={dataTab}
                    tabName={"profileTab"}
                />
            </div>
        </div>
    );
};

export default Header;
