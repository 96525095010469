import { Component } from 'react';
import PropTypes from 'prop-types';

const canUseDOM = () => {
  return typeof window !== 'undefined' && window.document && window.document.createElement;
};

export const ZendeskAPI = (...args) => {
  if (canUseDOM() && window.zE) {
    window.zE.apply(null, args);
  } else {
    console.warn('Zendesk is not initialized yet');
  }
};

export const OpenZendeskChat = () => {
  if (canUseDOM() && window.zE) {
    window.zE('messenger', 'show');
    window.zE('messenger', 'open'); 
  }
};

export const HideZendeskChat = () => {
  if (canUseDOM() && window.zE) {
    window.zE('messenger', 'hide')
  }
};

export default class Zendesk extends Component {
  constructor(props) {
    super(props);
    this.insertScript = this.insertScript.bind(this);
    this.onScriptLoaded = this.onScriptLoaded.bind(this);
  }

  onScriptLoaded() {
    if (typeof this.props.onLoaded === 'function') {
      this.props.onLoaded();
    }

    // Hide the widget initially after it's loaded
    window.zE('webWidget', 'hide');
  }

  insertScript(zendeskKey, defer) {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.async = true;
    script.addEventListener('load', this.onScriptLoaded);

    document.body.appendChild(script);
  }

  componentDidMount() {
    if (canUseDOM() && !window.zE) {
      const { zendeskKey } = this.props;
      this.insertScript(zendeskKey);

      window.zESettings = {
        webWidget: {
          chat: {
            connectOnPageLoad: false,  // Prevents automatic connection on page load
            enabled: true
          }
        }
      };
    }
  }

  componentWillUnmount() {
    if (!canUseDOM() || !window.zE) return;
    delete window.zE;
    delete window.zESettings;
  }

  render() {
    return null;
  }
}

Zendesk.propTypes = {
  zendeskKey: PropTypes.string.isRequired,
  defer: PropTypes.bool
};
