import React from "react";

const CircleLock = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="156"
            height="156"
            viewBox="0 0 156 156"
            fill="none"
        >
            <path
                d="M97.2141 66.9748H93.0309V57.6647C93.0281 55.4755 92.1621 53.3767 90.6225 51.8277C89.0829 50.2787 86.9952 49.4057 84.8164 49.4H71.0642C68.8854 49.4057 66.7977 50.2787 65.2581 51.8277C63.7185 53.3767 62.8525 55.4755 62.8496 57.6647V66.9748H58.634C57.171 66.9748 55.768 67.5588 54.7335 68.5982C53.6991 69.6376 53.1179 71.0474 53.1179 72.5173V101.057C53.1179 102.527 53.6991 103.937 54.7335 104.977C55.768 106.016 57.171 106.6 58.634 106.6H97.2141C98.677 106.6 100.08 106.016 101.115 104.977C102.149 103.937 102.73 102.527 102.73 101.057V72.5173C102.73 71.0474 102.149 69.6376 101.115 68.5982C100.08 67.5588 98.677 66.9748 97.2141 66.9748ZM68.2682 57.6647C68.2682 56.9186 68.5624 56.2029 69.0864 55.6743C69.6105 55.1458 70.3216 54.8474 71.0642 54.8445H84.7839C85.5265 54.8474 86.2376 55.1458 86.7616 55.6743C87.2857 56.2029 87.5799 56.9186 87.5799 57.6647V66.9748H68.2682V57.6647ZM80.189 90.1249V94.8834C80.1904 94.993 80.1702 95.1017 80.1294 95.2034C80.0887 95.3051 80.0282 95.3976 79.9516 95.4756C79.875 95.5536 79.7837 95.6155 79.6831 95.6578C79.5824 95.7001 79.4745 95.7218 79.3654 95.7218H76.5477C76.4387 95.7218 76.3306 95.7001 76.23 95.6578C76.1294 95.6155 76.0381 95.5536 75.9615 95.4756C75.8848 95.3976 75.8244 95.3051 75.7837 95.2034C75.7429 95.1017 75.7227 94.993 75.7241 94.8834V90.1249C74.3407 89.5998 73.1843 88.6038 72.4566 87.3103C71.7288 86.0168 71.4757 84.5077 71.7412 83.046C72.0068 81.5842 72.7743 80.2624 73.91 79.3107C75.0457 78.359 76.4777 77.8378 77.9565 77.8378C79.4354 77.8378 80.8673 78.359 82.0031 79.3107C83.1388 80.2624 83.9063 81.5842 84.1719 83.046C84.4374 84.5077 84.1843 86.0168 83.4565 87.3103C82.7287 88.6038 81.5724 89.5998 80.189 90.1249Z"
                fill="#F5490F"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M78 140.4C112.463 140.4 140.4 112.463 140.4 78C140.4 43.5374 112.463 15.6 78 15.6C43.5374 15.6 15.6 43.5374 15.6 78C15.6 112.463 43.5374 140.4 78 140.4ZM78 149.76C117.632 149.76 149.76 117.632 149.76 78C149.76 38.368 117.632 6.23999 78 6.23999C38.368 6.23999 6.23999 38.368 6.23999 78C6.23999 117.632 38.368 149.76 78 149.76Z"
                fill="#F5490F"
            />
        </svg>
    );
};

export default CircleLock;
