import {
    Backdrop,
    Fade,
    Modal,
    Typography,
    Box,
    Paper,
    Button,
    Grid,
    Stack,
    IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CurrencyNumber from "Components/CurrencyNumber";
import React from "react";
import "./style.scss";
import Close from "@mui/icons-material/Close";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
    },
});

const planIntervalPriority: any = {
    monthly: 1,
    yearly: 2,
    lifetime: 3,
};

const mappingDescription: any = {
    1: "$29/month, billed monthly", // Monthly family membership
    2: "$288/year, billed annually", // Annual family membership 
    49: "$38/month, billed monthly", // Monthly family membership with warranty
    50: "$396/year, billed annually",  // Annual family membership with warranty
    76: "$48/month, billed monthly",  // Monthly family membership With Warranty CAD
    77: "$528/year, billed annually", // Monthly family membership With Warranty CAD
    46: "$36/month, billed monthly", // Monthly family membership CAD
    47: "$384/year, billed annually",  // Annual family membership CAD
    56: "$48/month, billed monthly",  // Monthly family membership With Warranty CAD
    57: "$528/year, billed annually", // Monthly family membership With Warranty CAD
}

const KlaviyoUpgradeMembershipModal: React.FunctionComponent<{
    open: boolean;
    onClose: any;
    state: any;
    onSubmit: (planId: number) => void;
    plans: any[];
    membership?: any;
}> = ({ open, onClose, onSubmit, state, plans, membership = {} }) => {
    const classes = useStyles();
    const { change_plan_invoice_url, processing_change_plan } = state;
    const [avaiblePlans, setAvaiablePlans] = React.useState<any[]>([]);
    const [selectedPlanId, setSelectedPlanId] = React.useState<number>(
        membership?.planId
    );
    const [itemPlan, setItemPlan] = React.useState<any>({});
    React.useEffect(() => {
        if (plans?.length && membership) {
            setItemPlan(membership.plan);
            const avPlans = plans.filter((plan) => {
                return (
                    plan.id !== membership.plan.id &&
                    plan.customerType === membership.plan.customerType &&
                    planIntervalPriority[plan.interval] >=
                    planIntervalPriority[membership.plan.interval] &&
                    plan.type === "stripe" &&
                    plan.enable &&
                    plan.currency === membership.plan.currency &&
                    plan.productId === membership.plan.productId &&
                    // && plan.productId !== "warranty"
                    Number(plan.amount) > Number(membership.plan.amount) &&
                    membership.plan.appIds.some((appId: number) => { return plan.appIds.includes(appId) }) &&
                    membership.plan.maxMachines === plan.maxMachines &&
                    plan.interval === "yearly" &&
                    plan.intervalCount === 1
                );
            });
            setAvaiablePlans(avPlans);
            if (avPlans.length) {
                setSelectedPlanId(avPlans[0].id)
            }
        }
    }, [membership, plans]);

    // React.useEffect(() => {
    //     if (plans?.length) {
    //         const avPlans = plans
    //         setAvaiablePlans(avPlans.slice(0, 2));
    //         if (avPlans.length) {
    //             setSelectedPlanId(avPlans[0].id)
    //             setItemPlan(avPlans[0]);
    //         }
    //     }
    // }, [membership, plans]);

    const handleSubmit = () => {
        onSubmit(selectedPlanId);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className="klaviyo-upgrade-modal-component">
                    <IconButton
                        onClick={onClose}
                        className={"close-button"}
                    >
                        <Close />
                    </IconButton>
                    <Box>
                        <Typography component="h1" variant="h3" mb="8px">
                            UPGRADE MEMBERSHIP
                        </Typography>
                    </Box>
                    <div
                        style={{
                            marginBottom: "8px"
                        }}
                    >
                        <Paper sx={{ padding: "32px 18px", margin: "32px 0", boxShadow: "none" }} className="select-box select-box--current" >
                            <Box className="select-box--current__main">
                                <Grid
                                    textAlign="start"
                                    sx={{ fontFamily: "Work Sans" }}
                                    mb="8px"
                                >
                                    <Box className="select-box-marker select-box-marker--current">
                                        <div>CURRENT PLAN</div>
                                    </Box>
                                </Grid>
                                <Grid
                                    container
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <Typography
                                            component="h3"
                                            variant="h4"
                                            fontSize={"20px"}
                                            fontFamily={"Work Sans"}
                                            textTransform={"uppercase"}
                                        >
                                            {itemPlan.name}
                                        </Typography>
                                        <Typography
                                            component="p"
                                            variant="body1"
                                            fontSize={"16px"}
                                        >
                                            {mappingDescription[itemPlan?.id]}
                                        </Typography>
                                    </Grid>
                                    <Grid item display={"flex"} alignItems={"center"} flexDirection={"column"}>
                                        <Box width={"100%"} textAlign={"right"} sx={{
                                            textAlign: {
                                                xs: "left",
                                                md: "right"
                                            },
                                            marginTop: {
                                                xs: "24px",
                                                md: 0
                                            }
                                        }}>
                                            <Typography
                                                component="h4"
                                                variant="h5"
                                                fontSize={"24px"}
                                                fontFamily={"MonoSpec"}
                                                fontWeight={500}
                                            >
                                                <CurrencyNumber value={Math.round(itemPlan?.amount)} /><span>/MONTH</span>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                        {avaiblePlans.map((plan) => {
                            return (
                                <Paper
                                    key={plan.id}
                                    onClick={setSelectedPlanId.bind(null, plan.id)}
                                    className={`select-box ${plan.id === selectedPlanId
                                        ? "select-box__active"
                                        : null
                                        }`}
                                >
                                    <Grid
                                        textAlign="start"
                                        sx={{ fontFamily: "Work Sans" }}
                                        mb="8px"
                                    >
                                        <Box className="select-box-marker">
                                            <div>
                                                YOUR NEW PLAN
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        container
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Grid item>
                                            <Grid
                                                container
                                                display="flex"
                                                gap={"24px"}

                                            >
                                                <Grid item>
                                                    <h3>
                                                        {plan.name}
                                                    </h3>
                                                    <p>
                                                        {mappingDescription[plan.id]}
                                                    </p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item display={"flex"} alignItems={{
                                            xs: "baseline",
                                            md: "center"
                                        }} flexDirection={"column"}>
                                            <Box width={"100%"} textAlign={"right"} sx={{
                                                textAlign: {
                                                    xs: "left",
                                                    md: "right"
                                                },
                                                marginTop: {
                                                    md: 0,
                                                    xs: "24px",
                                                }
                                            }}>
                                                <Typography
                                                    component="h4"
                                                    variant="h5"
                                                    fontSize={"24px"}
                                                    fontFamily={"MonoSpec"}
                                                    fontWeight={500}
                                                >
                                                    <CurrencyNumber value={Math.round(plan?.amount / (12 * plan.intervalCount))} /><span>/MONTH</span>
                                                </Typography>
                                            </Box>
                                            <Box marginTop={"8px"}>
                                                <Typography
                                                    component="h5"
                                                    variant="h5"
                                                    fontSize={"16px"}
                                                    fontWeight={500}
                                                >
                                                    Save $5 per month
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            );
                        })}
                    </div>
                    {change_plan_invoice_url ? (
                        <p>
                            <br />
                            <br />
                            Can't charge your card. We will open a new tab
                            automatically to retry payment for membership.
                            Please reload page after payment successful. Please{" "}
                            <a
                                href={change_plan_invoice_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CLICK HERE
                            </a>{" "}
                            if nothing happens.
                        </p>
                    ) : null}
                    <Box>
                        <Typography>
                            {" "}
                            Once you click the button below, your new plan will begin immediately. You'll be charged right away, with the annual membership fee adjusted to subtract what you've already paid for this month.
                        </Typography>
                    </Box>
                    <Stack
                        spacing={2}
                        direction={{ xs: "column-reverse", md: "row" }}
                        alignItems="end"
                        justifyContent="flex-end"
                        width={{
                            xs: "100%",
                            md: "60%",
                        }}
                        sx={{
                            float: "right",
                        }}
                    >
                        <Button
                            className="confirm-button"
                            disabled={selectedPlanId === itemPlan.id}
                            variant="contained"
                            color="secondary"
                            size="medium"
                            onClick={handleSubmit}
                        >
                            {processing_change_plan ? (
                                <i className="fa fa-circle-o-notch fa-spin"></i>
                            ) : (
                                "CONFIRM UPGRADE"
                            )}
                        </Button>
                    </Stack>
                </div>
            </Fade>
        </Modal>
    );
};

export default KlaviyoUpgradeMembershipModal;
