export type MEMBERSHIP_TYPE = "monthly" | "annual";

class VWO {
    trackingMembershipCancellation({ type }: { type: MEMBERSHIP_TYPE }) {
        if (typeof window !== "undefined" && window.VWO && window.VWO.event) {
            window.VWO.event("membershipCancellation", {
                type,
            });
        } else {
            console.warn("VWO is not available");
        }
    }

    trackingOfferClaimed({ type }: { type: MEMBERSHIP_TYPE }) {
        if (typeof window !== "undefined" && window.VWO && window.VWO.event) {
            window.VWO.event("offerClaimed", {
                type,
            });
        } else {
            console.warn("VWO is not available");
        }
    }

    trackingCancellationInitiated({ type }: { type: MEMBERSHIP_TYPE }) {
        if (typeof window !== "undefined" && window.VWO && window.VWO.event) {
            window.VWO.event("cancellationInitiated", {
                type,
            });
        } else {
            console.warn("VWO is not available");
        }
    }
}

export const vwo = new VWO();
