import React from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import useLoginHook from "./Login.hook";
import useStyles from "./Login.styles";
import { Helmet } from "react-helmet";
import style from "./Login.module.scss";
import LoginLayout from "Layouts/LoginLayout";

const Login = () => {
    const classes: any = useStyles();
    const {
        handleSignIn,
        hasError,
        formState,
        handleChange,
        isProcessing,
        handleAddEmail,
    } = useLoginHook();

    // const loginWithSocial = (method: string) => {
    //     sessionStorage.setItem("redirect", window.location.href);
    //     window.location.href = `${ENV.SERVER_DOMAIN}/auth/${method}?next=${
    //         window.location.origin + CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY
    //     }`;
    // };
    return (
        <LoginLayout>
            <div className={`${classes.root} ${style["LoginWrapper"]}`}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Login | Aviron</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className={`${classes.content} ${style["content"]}`}>
                <div className={`${classes.contentBody} ${style["contentBody"]}`}>
                        <form
                            className={`${classes.form} ${style["form"]}`}
                            onSubmit={
                                formState.state === "email"
                                    ? handleAddEmail
                                    : handleSignIn
                            }
                        >
                            <div className={classes.logo}>
                                <img src="https://avironactive.com/assets/img/icon/aviron-icon.svg" alt="icon" />
                            </div>
                            <Typography
                                className={classes.title + ' ' + style['title']}
                                variant="h2"
                            >
                                LOG IN
                            </Typography>

                            <TextField
                                className={classes.textField}
                                error={hasError("email")}
                                fullWidth
                                helperText={
                                    hasError("email")
                                        ? formState.errors.email[0]
                                        : null
                                }
                                label="Email"
                                placeholder="Email or Username"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleChange}
                                type="text"
                                value={formState.values.email || ""}
                                variant="outlined"
                                data-cy="login-email-field"
                                sx={{
                                    marginTop: "16px",
                                }}
                            />
                            {formState.state !== "email" ? (
                                <>
                                    {formState.state === "token" ? (
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                textAlign: "left",
                                                marginTop: "24px",
                                            }}
                                            className={style['sentAOneTimeVerificationCode']}
                                        >
                                            We sent a one time verification code
                                            to your email. Please type or paste
                                            the code below
                                        </Typography>
                                    ) : null}
                                    <TextField
                                        className={classes.textField}
                                        error={hasError("password")}
                                        fullWidth
                                        helperText={
                                            hasError("password")
                                                ? formState.errors.password[0]
                                                : null
                                        }
                                        label={
                                            formState.state === "token"
                                                ? "Verification code"
                                                : "Password"
                                        }
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                        type="password"
                                        value={formState.values.password || ""}
                                        variant="outlined"
                                        data-cy="login-password-field"
                                        sx={{
                                            marginTop: "16px",
                                        }}
                                    />{" "}
                                </>
                            ) : null}
                            {formState &&
                            formState.errors &&
                            formState.errors.login ? (
                                <p className={classes.loginError}>
                                    {formState.errors.login}
                                </p>
                            ) : null}
                            {isProcessing ? (
                                <Button
                                    className={classes.signInButton + ' signInButton'}
                                    color="primary"
                                    disabled={!formState.isValid}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    data-cy="login-submit-button"
                                    sx={{
                                        marginTop: "24px",
                                    }}
                                >
                                    <i className="fas fa-circle-notch fa-spin"></i>
                                </Button>
                            ) : (
                                <Button
                                    className={classes.signInButton + ' signInButton'}
                                    color="primary"
                                    disabled={!formState.isValid}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    data-cy="login-submit-button"
                                    sx={{
                                        marginTop: "24px",
                                    }}
                                >
                                    {formState.state === "email"
                                        ? "Continue with email"
                                        : "Log in"}
                                </Button>
                            )}
                            {/* <div className={style['line']}>
                                <div className={style['orText']}>OR</div>
                            </div> */}
                            {/* <Button
                                className={classes.signInButton + ' googleSiginButton'}
                                fullWidth
                                type="button"
                                variant="outlined"
                                size="large"
                                sx={{
                                    borderRadius: "5px",
                                    textTransform: "none",
                                    fontFamily: "Work Sans",
                                    background: "#FFF",
                                    boxShadow: "0px 0px 1.57143px rgb(0 0 0 / 8%), 0px 1.04762px 1.57143px rgb(0 0 0 / 17%)"
                                }}
                                onClick={loginWithSocial.bind(null, "google")}
                            >
                                <img src="https://avironactive.com/assets/icon/google-icon.svg" alt="google-icon"></img>
                                Log in with Google
                            </Button> */}
                            <Typography
                                color="textSecondary"
                                variant="body1"
                                sx={{
                                    marginTop: "16px",
                                    textAlign: "center",
                                }}
                                className={style['forgotPassText']}
                            >
                                <Link
                                    component={RouterLink}
                                    to="/forgot_password"
                                    variant="h6"
                                >
                                    Forgot password?
                                </Link>
                            </Typography>
                        </form>
                    </div>
                </div>
            </div>
        </LoginLayout>
    );
};

export default Login;
