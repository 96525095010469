import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Field from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState, useContext } from "react";
import Button from "Components/base/Button/Button";
import style from "./style.module.scss";
import { formSchema } from "./formSchema";
import { gameServerApi } from "services/gameServerV2";
import { useParams } from "react-router-dom";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { CONSTANTS } from "consts";
import Swal from "sweetalert2";
import ErrorIcon from "Components/Icons/ErrorIcon.svg";
import SuccessInvite from "Components/Icons/SuccessInvite.svg";
import InputOutline from "Components/base/InputOutline";
import SelectField from "Components/FormElements/Select";
import InputLabel from "Components/base/InputLabel";
import { IMachine } from "Screens/Memberships/MachineManage/formatData";
import WarnignIcon from "Components/Icons/WarningIcon.svg";
import { errorAddMachineCode } from "services/gameServerV2/common/machine";
import { useWindowSize } from "scripts/useWindowSize";
import { useTrackingSubscriptionAllActionMutation } from "services/webServer";
import { UserAppContext } from "contexts";

interface InviteMemberProps {
    open: boolean;
    onClose: any;
    refetchData?: any;
    machine: IMachine;
}

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const SwitchMachineModal = (props: InviteMemberProps) => {
    const { open, onClose, refetchData, machine } = props;

    const [trackingSubscriptionAllAction] =
        useTrackingSubscriptionAllActionMutation();
    const { auth } = useContext(UserAppContext);
    const userId = useMemo(() => auth?.id, [auth]);

    const handleTrackingSwitchMachine = ({
        oldMachineId,
        newMachineId,
        status,
        reason
    }: {
        oldMachineId: string;
        newMachineId: string;
        status: string;
        reason?: string
    }) => {
        const reasonText = status === "failed" ? {reason}:{}
        trackingSubscriptionAllAction({
            variables: {
                id: params?.id,
                action: "switch_machine",
                payload: {
                    ...reasonText,
                    customer_id: userId,
                    oldMachine: oldMachineId,
                    newMachine: newMachineId,
                    customer_type: "user",
                    source: "cms",
                    status: status,
                },
                historyType: "conversion",
            },
        });
    };

    const classes = useStyles();
    const params = useParams<{ id: string }>();
    const [showAvironLoading, setShowAvironLoading] = useState(false);
    const windowSize = useWindowSize();
    const machineTypeOption = [
        {
            name: "Bike",
            value: CONSTANTS.APP.AVIRON_BIKE,
        },
        {
            name: "Rower",
            value: CONSTANTS.APP.AVIRON_APK,
        },
        {
            name: "Treadmill",
            value: CONSTANTS.APP.AVIRON_TREADMILL,
        },
    ];

    const initialValues = {
        appId: machine?.appId,
        machineId: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema({ oldMachineId: machine.machineId }),
        onSubmit: async (values) => {
            await handleSubmitForm(values);
        },
    });

    const handleSubmitForm = async (values: {
        appId: number;
        machineId: string;
    }) => {
        if (machine.machineId === values.machineId) {
            onClose();
            handleTrackingSwitchMachine({
                oldMachineId: machine?.machineId,
                newMachineId: values?.machineId,
                status: "failed",
                reason: "This product is already part of this membership"
            });
            await Swal.fire({
                title: "Product already exists",
                html: "<div>This product is already part of this membership</div>",
                imageUrl: ErrorIcon,
                showClass: {
                    popup: `
                          error-invite-member-popup
                        `,
                },
            });
            return;
        }
        try {
            const htmlText =
                windowSize?.width >= 500
                    ? `<div>Once you confirm, the product with Machine ID: <br /><span style="font-weight: 500;">${machine.machineId}</span> 
                will no longer have access to this <br />membership. 
                It will be replaced by a new product with <br/>Machine ID: <span style="font-weight: 500;">${values.machineId}</span>.</div>`
                    : `<div>Once you confirm, the product with Machine ID: <span style="font-weight: 500;">${machine.machineId}</span> 
                will no longer have access to this membership. 
                It will be replaced by a new product with Machine ID: <span style="font-weight: 500;">${values.machineId}</span>.</div>`;
            const result = await Swal.fire({
                title: '<div style="font-size: 24px;">Confirm replace product<div>',
                // icon: "warning",
                imageUrl: WarnignIcon,
                html: htmlText,
                showCancelButton: true,
                confirmButtonText: "CONFIRM",
                cancelButtonText: "Cancel",
                showClass: {
                    popup: `
                      confirm-membership-popup
                    `,
                },
            });
            if (!result.isConfirmed) {
                return;
            }

            // Swal.fire({
            //     title: "Success",
            //     html: "<div>Product was successfully replaced.</div>",
            //     imageUrl: SuccessInvite,
            //     showClass: {
            //         popup: `error-invite-member-popup`,
            //     },
            // });
            // return

            setShowAvironLoading(true);
            const { appId, machineId } = values;
            await gameServerApi.machine.switchMembershipMachine({
                //@ts-ignore
                orgId: params?.id,
                newMachineId: machineId,
                oldMachineId: machine.machineId,
                appId: appId,
            });
            onClose();
            setShowAvironLoading(false);
            await refetchData();
            handleTrackingSwitchMachine({
                oldMachineId: machine?.machineId,
                newMachineId: values?.machineId,
                status: "success",
            });
            setTimeout(() => {
                Swal.fire({
                    title: "Success",
                    html: "<div>Product was successfully replaced.</div>",
                    imageUrl: SuccessInvite,
                    showClass: {
                        popup: `error-invite-member-popup`,
                    },
                });
            }, 600);
        } catch (error) {

            onClose();
            setTimeout(() => {
                if (
                    error.response.status === 409 &&
                    error?.response?.data?.error?.code ===
                        errorAddMachineCode.PRODUCT_NOT_FOUND_CODE
                ) {
                    const errorMsg = error?.response?.data?.error?.message;
                    if(errorMsg === errorAddMachineCode.MACHINE_NOT_FOUND) {
                        handleTrackingSwitchMachine({
                            oldMachineId: machine?.machineId,
                            newMachineId: values?.machineId,
                            status: "failed",
                            reason: "Product not found"
                        });
                        Swal.fire({
                            title: "Product not found",
                            html: "<div>Please double-check the Machine ID and try again.</div>",
                            imageUrl: ErrorIcon,
                            showClass: {
                                popup: `
                                      error-invite-member-popup
                                    `,
                            },
                        });
                    } else if(errorMsg === errorAddMachineCode.MACHINE_ALREADY_EXISTS) {
                        handleTrackingSwitchMachine({
                            oldMachineId: machine?.machineId,
                            newMachineId: values?.machineId,
                            status: "failed",
                            reason: "Membership already exists"
                        });
                        Swal.fire({
                            title: "Product Activated",
                            html: "<div>A membership already exists. Please contact support@avironactive.com for assistance.</div>",
                            imageUrl: ErrorIcon,
                            showClass: {
                                popup: `
                                      error-invite-member-popup
                                    `,
                            },
                        });
                    } else {
                        handleTrackingSwitchMachine({
                            oldMachineId: machine?.machineId,
                            newMachineId: values?.machineId,
                            status: "failed",
                            reason: errorMsg
                        });
                        Swal.fire({
                            title: "Error",
                            html: `<div>${errorMsg}</div>`,
                            imageUrl: ErrorIcon,
                            showClass: {
                                popup: `
                                      error-invite-member-popup
                                    `,
                            },
                        });
                    }
                } else if (
                    error.response.status === 409 &&
                    error?.response?.data?.error?.code ===
                        errorAddMachineCode.PRODUCT_LIMIT_NUMBER_CODE
                ) {
                    handleTrackingSwitchMachine({
                        oldMachineId: machine?.machineId,
                        newMachineId: values?.machineId,
                        status: "failed",
                        reason: "Maximum number of Product connected"
                    });
                    Swal.fire({
                        title: "ERROR",
                        html: "<div>Maximum number of Product connected. <br/>Please delete some to add more.</div>",
                        imageUrl: ErrorIcon,
                        showClass: {
                            popup: `
                                  error-invite-member-popup
                                `,
                        },
                    });
                } else if (
                    error.response.status === 400 &&
                    error?.response?.data?.error?.code ===
                        errorAddMachineCode.PRODUCT_EXIST_CODE
                ) {
                    handleTrackingSwitchMachine({
                        oldMachineId: machine?.machineId,
                        newMachineId: values?.machineId,
                        status: "failed",
                        reason: "Product is already part of this membership"
                    });
                    Swal.fire({
                        title: "Product already exists",
                        html: "<div>This product is already part of this membership.</div>",
                        imageUrl: ErrorIcon,
                        showClass: {
                            popup: `
                                  error-invite-member-popup
                                `,
                        },
                    });
                } else {
                    handleTrackingSwitchMachine({
                        oldMachineId: machine?.machineId,
                        newMachineId: values?.machineId,
                        status: "failed",
                        reason: error?.response?.data?.error?.message
                    });
                    Swal.fire({
                        title: "Error",
                        html: `<div>${
                            error?.response?.data?.error?.message}</div>`,
                        imageUrl: ErrorIcon,
                        showClass: {
                            popup: `
                                  error-invite-member-popup
                                `,
                        },
                    });
                }
            }, 600);
            setShowAvironLoading(false);
        }
    };

    React.useEffect(() => {
        if (!open) {
            formik.resetForm();
        }
    }, [open]);

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal + " " + style["modalCustom"]}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 800,
                }}
            >
                <Fade in={open}>
                    <div className={style["change-password-modal"]}>
                        <div className={style["close-btn"]} onClick={onClose}>
                            <img
                                src="/images/icons/x-icon.svg"
                                width={18}
                                height={18}
                                alt="icon"
                            />
                        </div>

                        <div className={style["titleModal"]}>
                            REplaCE PRODUCT
                        </div>
                        <div className={style["desc"]}>
                            Replace this product with a different one to start
                            using your membership on the new product
                        </div>
                        <Box>
                            <form onSubmit={formik.handleSubmit}>
                                <div className={style["text-field-group"]}>
                                    <InputLabel htmlFor="outlined-machine-type">
                                        Please choose the type of new product
                                    </InputLabel>
                                    <SelectField
                                        options={machineTypeOption}
                                        value={formik.values.appId}
                                        onChange={formik.handleChange}
                                        name={"appId"}
                                    />
                                </div>
                                <div className={style["text-field-group"]}>
                                    <InputOutline
                                        label="Please enter the Machine ID of the new product"
                                        name="machineId"
                                        value={formik.values.machineId}
                                        onChange={formik.handleChange}
                                        error={formik.errors.machineId}
                                        placeholder="Machine ID"
                                    />
                                </div>
                                <div className={style["buttons-group"]}>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                            height: "52px",
                                        }}
                                        className={style["cancleButton"]}
                                        onClick={onClose}
                                    >
                                        Exit
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        sx={{
                                            height: "52px",
                                        }}
                                    >
                                        Replace
                                    </Button>
                                </div>
                            </form>
                        </Box>
                    </div>
                </Fade>
            </Modal>
            {showAvironLoading && <AvironLoading />}
        </>
    );
};

export default SwitchMachineModal;
