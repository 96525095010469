interface IErrorRes {
    status: number;
    data: {
        error: {
            code: number;
            message: string;
            description: string;
        };
        message: string;
    };
}
export const PERMISION_ERROR_CODE = 1016
export const handleError = (errorRes: IErrorRes) => {
  const {status, data} = errorRes;
  if(status === 401 && data.error.code === PERMISION_ERROR_CODE) {
    return 'error_permission'
  } else {
    return 'unknow'
  }
};
