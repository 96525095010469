import React, { Ref, forwardRef, useImperativeHandle, useState } from "react";
import {
    Backdrop,
    Box,
    Button,
    IconButton,
    Modal,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Close from "@mui/icons-material/Close";
import styles from "./MembershipRedeemOfferModal.module.scss";
import useWindowSize from "Hooks/useWindowSize";
import useQueryParams from "Hooks/useQueryParams";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";
import { GetAvironSubscriptionQuery } from "services/webServer";
import { useTrackingData } from "Components/TrackingData";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

export interface IMembershipRedeemOfferModalRef {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
}

interface MembershipRedeemOfferModalProps {
    data: GetAvironSubscriptionQuery;
}

const MembershipRedeemOfferModal = (
    { data }: MembershipRedeemOfferModalProps,
    ref: Ref<IMembershipRedeemOfferModalRef>
) => {
    const { id = "" } = useQueryParams();
    const history = useHistory();
    const isDesktop = useWindowSize(1280).width > 768;
    const classes = useStyles();
    const { trackingAgreeOfferClick } = useTrackingData(id);

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleClose = () => {
        setIsVisible(false);
        trackingAgreeOfferClick(data);
        history.replace(`${CONSTANTS.NAVIGATION_URL.MEMBERSHIPS}/${id}`);
    };

    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );

    return (
        <Modal
            open={isVisible}
            onClose={handleClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
            <div className={styles["membership-canceled-modal"]}>
                <IconButton
                    onClick={handleClose}
                    className={styles["close-button"]}
                >
                    <Close />
                </IconButton>
                <Box>
                    <div className={styles["membership-canceled-modal-image"]}>
                        <img
                            src="/images/membership/redeem-offer.webp"
                            alt="redeem"
                        />
                    </div>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: {
                                xs: "20px",
                                md: "24px",
                            },
                            lineHeight: {
                                xs: "20px",
                                md: "24.48px",
                            },
                            letterSpacing: "0.01em",
                            textAlign: "center",
                            color: "#161616",
                            marginBottom: "24px",
                            fontFamily: "GRIFTER",
                        }}
                    >
                        OFFER CLAIMED
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "20px",
                            alignItems: "center",
                            textAlign: "center",
                            marginBottom: {
                                xs: "32px",
                                md: "56px",
                            },
                        }}
                    >
                        You've snagged a sweet deal! Get ready to jump back in.{" "}
                        {isDesktop && <br />}
                        We can’t wait to see what you’ll achieve.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        textAlign: "center",
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        color="secondary"
                        fullWidth
                        sx={{
                            marginBottom: "24px",
                            width: {
                                xs: "100%",
                                md: "70%",
                            },
                            margin: "auto",
                        }}
                    >
                        OK
                    </Button>
                </Box>
            </div>
        </Modal>
    );
};

export default forwardRef(MembershipRedeemOfferModal);
