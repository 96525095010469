import CardSimple from "Components/CardSimple";
import LoadingHOC from "Components/LoadingHOC";
import { CONSTANTS } from "consts";
import dayjs from "dayjs";
import React, { useEffect, useLayoutEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import {
    generateDateObjByTimezone as DateJsByTimeZone,
    formatStringDatetimeRequest,
} from "utils";
import style from "./workoutCalendart.module.scss";
import useCalendartWorkoutHook from "Hooks/CalendartWorkoutHook";
import { UserAppContext } from "contexts";

const HIGHLIGHT_CLASS = "react-datepicker__day--highlighted";
const OUTSIDE_DAYS_CLASS = "react-datepicker__day--outside-month";
const Calendart = () => {
    const history = useHistory();
    const {
        data: workouDates,
        getCalendarStatisticDetailWorkout,
        loading,
    } = useCalendartWorkoutHook();
    const { timezone } = React.useContext(UserAppContext);
    const timeZone = timezone?.timezone;
    const todayStr = timeZone && DateJsByTimeZone(new Date(), timeZone).format(
        "YYYY-MM-DD 00:00:00"
    );

    useLayoutEffect(() => {
        const startStreakDate = formatStringDatetimeRequest(
            dayjs(todayStr)
                .startOf("month")
                .subtract(15, "day")
                .format("YYYY-MM-DD 00:00:00")
        );
        const endStreakDate = formatStringDatetimeRequest(
            dayjs(todayStr)
                .endOf("month")
                .add(15, "day")
                .format("YYYY-MM-DD 23:59:59")
        );

        getCalendarStatisticDetailWorkout(startStreakDate, endStreakDate);
    }, []);
    const handleChangeDate = (dateTime: any, event: any) => {
        const isHighlight =
            event.target.parentElement.classList.contains(HIGHLIGHT_CLASS);
        const isOutsideDays =
            event.target.parentElement.classList.contains(OUTSIDE_DAYS_CLASS);
        if (isHighlight) {
            history.push(
                `${
                    CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY_DETAIL
                }?page=1&startTime=${dayjs(dateTime).format(
                    "YYYY-MM-DD 00:00:00"
                )}&endTime=${dayjs(dateTime).format("YYYY-MM-DD 23:59:59")}`
            );
        } else if (!isHighlight && isOutsideDays) {
            const startStreakDate = formatStringDatetimeRequest(
                dayjs(dateTime)
                    .startOf("month")
                    .subtract(15, "day")
                    .format("YYYY:MM:DD 00:00:00")
            );
            const endStreakDate = formatStringDatetimeRequest(
                dayjs(dateTime)
                    .endOf("month")
                    .add(15, "day")
                    .format("YYYY:MM:DD 23:59:59")
            );
            getCalendarStatisticDetailWorkout(startStreakDate, endStreakDate);
        } else {
            return;
        }
    };
    const onMonthChange = (date: any) => {
        const startStreakDate = formatStringDatetimeRequest(
            dayjs(date)
                .startOf("month")
                .subtract(15, "day")
                .format("YYYY:MM:DD 00:00:00")
        );
        const endStreakDate = formatStringDatetimeRequest(
            dayjs(date)
                .endOf("month")
                .add(15, "day")
                .format("YYYY:MM:DD 23:59:59")
        );
        getCalendarStatisticDetailWorkout(startStreakDate, endStreakDate);
    };
    const onYearChange = (date: any) => {
        const startStreakDate = formatStringDatetimeRequest(
            dayjs(date)
                .startOf("month")
                .subtract(15, "day")
                .format("YYYY:MM:DD 00:00:00")
        );
        const endStreakDate = formatStringDatetimeRequest(
            dayjs(date)
                .endOf("month")
                .add(15, "day")
                .format("YYYY:MM:DD 23:59:59")
        );
        getCalendarStatisticDetailWorkout(startStreakDate, endStreakDate);
    };
    const toMiliseconds = (date: any) => {
        return new Date(dayjs(date).format("YYYY-MMM-DD 00:00:00")).getTime();
    };
    const customDateClassName = (date: any) => {
        //@ts-ignore
        const streaks = workouDates?.streaks;
        let dateClassname = "";
        if (new Date(todayStr).getTime() === date.getTime()) {
            dateClassname = "todayByTimezone";
        }
        for (let i = streaks.length - 1; i >= 0; i--) {
            const streak = streaks[i];
            if (streak && streak.length > 0) {
                //@ts-ignore
                if (
                    toMiliseconds(streak[0]) < date.getTime() &&
                    date.getTime() < toMiliseconds(streak[1])
                ) {
                    dateClassname = "dateInHighlighRange";
                } else if (toMiliseconds(streak[0]) === date.getTime()) {
                    dateClassname = "dateInHighlighRange dateMin";
                } else if (toMiliseconds(streak[1]) === date.getTime()) {
                    dateClassname = "dateInHighlighRange dateMax";
                }
            }
        }
        return dateClassname;
    };
    const renderDayContents = (day: any, date: any) => {
        return <div className={`dateCustom`}>{day}</div>;
    };

    return (
        <div className={style["workoutCalendartWrapper"]}>
            <CardSimple title={"CALENDAR"}>
                <>
                    {loading && (
                        <div className={style["loadingWrapper"]}>
                            <LoadingHOC isLoading={loading} />
                        </div>
                    )}
                </>
                {
                    //@ts-ignore
                    workouDates && workouDates?.streaks && (
                        <DatePicker
                            onChange={handleChangeDate}
                            // selected={new Date(todayStr)}
                            inline
                            //@ts-ignore
                            highlightDates={workouDates?.workoutDates}
                            dayClassName={customDateClassName}
                            renderDayContents={renderDayContents}
                            onMonthChange={onMonthChange}
                            onYearChange={onYearChange}
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={3}
                            calendarStartDay={1}
                            fixedHeight
                        />
                    )
                }
            </CardSimple>
        </div>
    );
};

export default Calendart;
