import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React from "react";
import style from "./SendEmailSuccessModal.module.scss";
import Button from "@mui/material/Button";
import SendMailSuccessIcon from 'Components/Icons/SendMailSuccess'

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const SendEmailSuccessModal = (props: any) => {
    const { open, onClose, oldEmail } = props;
    const classes = useStyles();

    const handleClickOk = () => {
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal + " " + style["modalCustom"]}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 800,
            }}
        >
            <Fade in={open}>
                <div className={style["change-password-modal"]}>
                    <div className={style["close-btn"]} onClick={onClose}>
                        <img
                            src="/images/icons/x-icon.svg"
                            width={18}
                            height={18}
                            alt="icon"
                        />
                    </div>
                    <Box>
                        <div className={style['successIcon']}>
                            <SendMailSuccessIcon />
                        </div>
                        <div className={style['title']}>
                            VERIFY YOUR EMAIL
                        </div>
                        <div className={style['text']}>
                            We sent an email to 
                            <br/><b>{oldEmail}</b>
                            <br/>
                            <br/>
                            Almost there! Head to your inbox and click the link <br />we sent you to update a new email
                        </div>
                        <div className={style["buttons-group"]}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{
                                    height: "64px",
                                }}
                                onClick={handleClickOk}
                            >
                                OK
                            </Button>
                        </div>
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};

export default SendEmailSuccessModal;
