import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DataTable from 'Components/DataTable';
import { AvironLoading } from 'Components/Icons/AvironLoading';
import PageTitle from 'Components/PageTitle';
import { CONSTANTS } from 'consts';
import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { ENV } from 'scripts/environment';
import { mobileDetect } from 'scripts/mobileDetect';
import { useGetOrdersByEmailQuery } from 'services/vendure';
import { useSharedQuery } from 'services/webServer';
import OrderCard from './OrderCard';
import "./style.scss";

const collums: any = [
    {
        name: "#",
        id: "no",
    },
    {
        name: "Order number",
        id: "code",
        checkStatus: true
    },
    {
        name: "Order date",
        id: "placedAt"
    },
    {
        name: "Total amount",
        id: "totalAmount",
        type: "currency"
    },
    {
        name: "Status",
        id: "status",
        checkStatus: true
    }
];

const stateColors = [
    { id: "Success", value: "Success", color: "#16C231" }
]

export default function OrderMain() {
    const history = useHistory();
    const isMobile = mobileDetect();
    const { data: userData } = useSharedQuery();
    const { data: orderData, loading, refetch, error } = useGetOrdersByEmailQuery({
        variables: {
            email: get(userData, "currentUser.emails.edges.0.node.email")
        },
        context: {
            clientName: "vendure"
        }
    });
    React.useEffect(() => {
        refetch();
    }, [history.action])
    const getOrderStatus = (state: string) => {
        switch (state) {
            case "PaymentSettled":
                return "Success";
            case "Processed":
                return "Success";
            case "Deposited":
                return "Deposited";
            default:
                return "Processing";
        }
    }
    const handClickDetail = (id: string) => {
        history.push(`${CONSTANTS.NAVIGATION_URL.ORDERS}/${id}`)
    }
    const getOrderCodeCell = (code: string) => {
        return <u onClick={handClickDetail.bind(null, code)} className="order-number-cell">{code}</u>
    }
    const rows: any[] = (get(orderData, "getOrdersByEmail.items") || []).map((item: any, index: number) => {
        return {
            id: item.code,
            no: index + 1,
            code: getOrderCodeCell(item.code),
            placedAt: item.orderPlacedAt ? moment(item.orderPlacedAt).format('MM/DD/YYYY') : "",
            totalAmount: item.totalWithTax / 100,
            status: getOrderStatus(item.state),
            state: item.state
        }
    });
    if (loading) {
        return (
            <div className='height-loading'>
                <AvironLoading />
            </div>
        )
    }
    if (!loading && rows.length === 0 || error) {
        return <Box sx={{
            width: "fit-content",
            margin: "32px auto",
            textAlign: "center"
        }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Orders | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <img src="/images/icons/document.svg" alt="icon" />
            <Typography mt="24px" mb="16px" variant="h4" component="h4">You don't have any orders yet.</Typography>
            <Typography mb="16px" variant="body1" component="p">You can go to shop page to purchase an order.</Typography>
            <a href={`${ENV.STOREFRONT_DOMAIN}/shop`}>
                <Button variant="contained" color="secondary" sx={{ width: "167px" }}>SHOP NOW</Button>
            </a>
        </Box>
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Orders | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Box sx={{
                marginBottom: "16px",
            }}>
                <PageTitle>Orders</PageTitle>
            </Box>
            {!isMobile ?
                <Box>
                    <DataTable collums={collums} rows={rows} colors={stateColors} colorField="status" />
                </Box> :
                <Box>
                    {rows.map((row: any) => {
                        return <OrderCard {...row} stateColors={stateColors} handClickDetail={handClickDetail} />
                    })}
                </Box>}
        </>
    )
}