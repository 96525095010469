import * as React from "react";
import Popover from "@mui/material/Popover";
import style from "./Actions.module.scss";
import { ReactComponent as ActionIcon } from "./threeDot.svg";
import TrashIcon from "Components/Icons/TrashCan";
import ResendIcon from "Components/Icons/Resend";
import { MEMBERSHIP_STATUS } from "Components/MembershipPage/InviteStatus";
import { ClickAwayListener } from "@mui/material";

interface ActionsProps {
    content: Array<{ name: string; action: any }>;
    selection?: {
        isBlock?: boolean;
        rowData?: {
            status?: number;
        };
    };
    hideActions: Array<"resend" | "remove" | "">;
}
const Actions = ({ content, selection, hideActions }: ActionsProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    // console.log('bbbbbbbbbbbbbbbb', selection);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div className={style["Actions"]}>
            {!selection?.isBlock && (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <>
                        <div
                            //@ts-ignore
                            onClick={handleClick}
                            style={{ cursor: "pointer" }}
                            //@ts-ignore
                            className={
                                selection?.isBlock && style["blockActionIcon"]
                            }
                        >
                            <ActionIcon />
                        </div>

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            className={style["actionsMenu"] + " actionsMenu"}
                        >
                            {content.map((item, key) => {
                                return (
                                    <>
                                        {!selection?.isBlock &&
                                            !hideActions?.includes(
                                                "remove"
                                            ) && (
                                                <>
                                                    {item.name === "remove" &&
                                                        !selection?.isBlock && (
                                                            <div
                                                                className={
                                                                    style[
                                                                        "itemAction"
                                                                    ]
                                                                }
                                                                key={key}
                                                                onClick={() => {
                                                                    setAnchorEl(
                                                                        null
                                                                    );
                                                                    item?.action(
                                                                        [
                                                                            selection,
                                                                        ]
                                                                    );
                                                                }}
                                                            >
                                                                <div
                                                                    className={
                                                                        style[
                                                                            "icon"
                                                                        ]
                                                                    }
                                                                >
                                                                    <TrashIcon />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        style[
                                                                            "item"
                                                                        ]
                                                                    }
                                                                >
                                                                    Remove
                                                                </div>
                                                            </div>
                                                        )}
                                                    {item.name === "resend" &&
                                                        !hideActions?.includes(
                                                            "resend"
                                                        ) && (
                                                            <div
                                                                className={
                                                                    style[
                                                                        "itemAction"
                                                                    ]
                                                                }
                                                                key={key}
                                                                onClick={() => {
                                                                    setAnchorEl(
                                                                        null
                                                                    );
                                                                    item?.action(
                                                                        [
                                                                            selection,
                                                                        ]
                                                                    );
                                                                }}
                                                            >
                                                                <div
                                                                    className={
                                                                        style[
                                                                            "icon"
                                                                        ]
                                                                    }
                                                                >
                                                                    <ResendIcon />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        style[
                                                                            "item"
                                                                        ]
                                                                    }
                                                                >
                                                                    Resend
                                                                    Invite
                                                                </div>
                                                            </div>
                                                        )}
                                                </>
                                            )}
                                    </>
                                );
                            })}
                        </Popover>
                    </>
                </ClickAwayListener>
            )}
        </div>
    );
};

export default Actions;
