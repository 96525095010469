import React from "react";
const GreaterArrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.6 1.4L2 0L8 6L2 12L0.6 10.6L5.2 6L0.6 1.4Z"
                fill="#FF4106"
            />
        </svg>
    );
};

export default GreaterArrow;
