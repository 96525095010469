import {
    Backdrop,
    Box,
    Button,
    Fade,
    IconButton,
    Modal,
    Stack,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import "./style.scss";
import Close from "@mui/icons-material/Close";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});


const ReactiveMembershipModal: React.FunctionComponent<{
    open: boolean;
    onClose: any;
    onSubmit: () => void;
}> = ({ open, onClose, onSubmit }) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className="reactive-membership-notice-detail-component">
                    <IconButton onClick={onClose} className="close-button">
                        <Close></Close>
                    </IconButton>
                    <div className="reactive-membership-notice-detail-component__main">
                        <Box
                            textAlign={"center"}
                            className="reactive-membership-notice-detail-component__main__item reactive-membership-notice-detail-component__main__item--content"
                        >
                            <img src="/images/icons/reactive-icon.svg" alt="icon" />
                            <Typography
                                component="h1"
                                variant="h1"
                                mb="8px"
                                mt="56px"
                            >
                                ACTIVATE MY 30 DAYS FREE
                            </Typography>
                            <Typography fontSize={"16px"}>
                            Enjoy all new features free for 30 days. Continue seamlessly or cancel anytime before billing starts at the end of your 30 days.
                            </Typography>
                            <Button
                                className="btn-claim-offer"
                                variant="contained"
                                onClick={onSubmit}
                                color="secondary"
                                // fullWidth
                                sx={{
                                    marginTop: "24px",
                                    marginBottom: "8px",
                                }}
                            >
                                START 30 DAYS FREE
                            </Button>
                        </Box>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default ReactiveMembershipModal;
