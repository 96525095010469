import React from "react";
import style from "./UserCard.module.scss";
import Avatar from "@mui/material/Avatar";

interface UserCard {
  avatar?: any;
  username?: any;
  email?: any;
  hideAvatar?: boolean;
}

const UserCard = ({
  avatar,
  username,
  email,
}: UserCard) => {
  return (
    <div className={style["UserCard"] + ' UserCardWrapper'}>
        <div className={style["avatar"] + ' avatar'}>
          {avatar ? (
            <Avatar alt="Remy Sharp" src={avatar} />
          ) : (
            <Avatar sx={{ bgcolor: "#F5490F" }}>{username ? username[0]?.toUpperCase() : ''}</Avatar>
          )}
        </div>
      <div className={style["textBlock"] + ' textBlock'}>
        {username && <div className={style["username"] + ' username'}>{username.toUpperCase()}</div>}
        {email &&  <div className={style["email"] + ' email'}>{email}</div>}
      </div>
    </div>
  );
};

export default UserCard;
