import { useGetAvironSubscriptionQuery } from "services/webServer";

export const useGetSubscriptionData = (id: string) => {
    const {
        data,
        loading: isLoadingSubscription,
        error,
    } = useGetAvironSubscriptionQuery({
        variables: {
            id,
        },
    });
    const isError = !!error || Boolean(!data?.avironSubscription);

    return {
        subscriptionData: data,
        isLoadingSubscription,
        error: isError,
    };
};
