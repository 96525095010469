import {
    GetAvironSubscriptionQuery,
    useGetMachineBySubscriptionIdQuery,
    useTrackingSubscriptionAllActionMutation,
} from "services/webServer";

type DataTrackingType = GetAvironSubscriptionQuery | undefined;

enum SUBSCRIPTION_TYPE {
    MONTHLY = "monthly",
    ANNUAL = "yearly",
}

const handleRenderSubscriptionType = (type: string) => {
    switch (type) {
        case SUBSCRIPTION_TYPE.MONTHLY:
            return "monthly";
        case SUBSCRIPTION_TYPE.ANNUAL:
            return "annual";

        default:
            return SUBSCRIPTION_TYPE.MONTHLY;
    }
};

export const useTrackingData = (subscriptionId: string) => {
    const [trackingSubscriptionAllAction] =
        useTrackingSubscriptionAllActionMutation();
    const { data } = useGetMachineBySubscriptionIdQuery({
        variables: {
            subscriptionId: subscriptionId || "",
        },
        skip: Boolean(!subscriptionId),
    });

    const machineId = data?.getMachineBySubscriptionId?.machine_id;

    const trackingPauseOrCancelMembershipClick = (
        data: GetAvironSubscriptionQuery
    ) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "pause_cancel_membership_click",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    type: "monthly",
                },
                historyType: "behavior",
            },
        });
    };

    const trackingPauseOrCancelScreenView = (
        data: GetAvironSubscriptionQuery
    ) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "pause_cancel_screen_view",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    type: "monthly",
                },
                historyType: "behavior",
            },
        });
    };

    const trackingPauseClick = (data: DataTrackingType) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "pause_click",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    type: "monthly",
                    entry: "pause_cancel_screen_view",
                },
                historyType: "behavior",
            },
        });
    };

    const trackingCancelClick = (data: DataTrackingType) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "cancel_click",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    type: "monthly",
                    entry: "pause_cancel_screen_view",
                },
                historyType: "behavior",
            },
        });
    };

    const trackingFeedbackScreenView = (data: DataTrackingType) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "feedback_screen_view",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    type: handleRenderSubscriptionType(
                        data?.avironSubscription?.plan?.interval as string
                    ),
                },
                historyType: "behavior",
            },
        });
    };

    const trackingPauseInsteadClick = (data: DataTrackingType) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "pause_instead_click",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    entry: "feedback_screen_view",
                    type: "monthly",
                },
                historyType: "behavior",
            },
        });
    };

    const trackingContinueCancelingClick = (
        data: DataTrackingType,
        reason: string
    ) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "continue_canceling_click",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    entry: "feedback_screen_view",
                    reason,
                    type: handleRenderSubscriptionType(
                        data?.avironSubscription?.plan?.interval as string
                    ),
                },
                historyType: "behavior",
            },
        });
    };

    const trackingOfferPageView = (data: DataTrackingType) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "offer_page_view",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    entry: "continue_canceling_click",
                    type: handleRenderSubscriptionType(
                        data?.avironSubscription?.plan?.interval as string
                    ),
                },
                historyType: "behavior",
            },
        });
    };

    const trackingOfferRedeemClick = (data: DataTrackingType) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "offer_redeem_click",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    entry: "offer_page_view",
                    type: handleRenderSubscriptionType(
                        data?.avironSubscription?.plan?.interval as string
                    ),
                },
                historyType: "behavior",
            },
        });
    };

    const trackingOfferRejectClick = (data: DataTrackingType) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "offer_reject_click",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    entry: "offer_page_view",
                    type: handleRenderSubscriptionType(
                        data?.avironSubscription?.plan?.interval as string
                    ),
                },
                historyType: "behavior",
            },
        });
    };

    const trackngOfferConfirmScreenView = (data: DataTrackingType) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "offer_confirm_screen_view",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    entry: "offer_redeem_click",
                    type: handleRenderSubscriptionType(
                        data?.avironSubscription?.plan?.interval as string
                    ),
                },
                historyType: "behavior",
            },
        });
    };

    const trackingAgreeOfferClick = (data: DataTrackingType) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "offer_claimed_popup",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    entry: "agree_offer_click",
                    type: handleRenderSubscriptionType(
                        data?.avironSubscription?.plan?.interval as string
                    ),
                },
                historyType: "behavior",
            },
        });
    };

    const trackingReviewAndCancelView = (
        data: DataTrackingType,
        entry: string
    ) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "review_cancel_view",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    entry,
                    type: handleRenderSubscriptionType(
                        data?.avironSubscription?.plan?.interval as string
                    ),
                },
                historyType: "behavior",
            },
        });
    };

    const trackingNotCancelClick = (data: DataTrackingType, entry: string) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "not_cancel_click",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    entry,
                    type: handleRenderSubscriptionType(
                        data?.avironSubscription?.plan?.interval as string
                    ),
                },
                historyType: "behavior",
            },
        });
    };

    const trackingCancelMembershipClick = (data: DataTrackingType) => {
        if (!data) return;
        trackingSubscriptionAllAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "cancel_membership_click",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    machine_id: machineId,
                    customer_type: "user",
                    source: "cms",
                    type: "annual",
                },
                historyType: "behavior",
            },
        });
    };

    return {
        trackingPauseOrCancelMembershipClick,
        trackingPauseOrCancelScreenView,
        trackingPauseClick,
        trackingCancelClick,
        trackingFeedbackScreenView,
        trackingPauseInsteadClick,
        trackingContinueCancelingClick,
        trackingOfferPageView,
        trackingOfferRedeemClick,
        trackingOfferRejectClick,
        trackngOfferConfirmScreenView,
        trackingAgreeOfferClick,
        trackingReviewAndCancelView,
        trackingNotCancelClick,
        trackingCancelMembershipClick,
    };
};
