import {
    useCheckIsCanPauseSubscriptionQuery,
    useGetAvironSubscriptionQuery,
} from "services/webServer";

export const useCheckCanPauseMembership = (id: string) => {
    const {
        data: checkCanPauseData,
        loading: isLoadingCheckCanPauseSubscription,
    } = useCheckIsCanPauseSubscriptionQuery({
        variables: {
            subscriptionId: id || "",
        },
        skip: Boolean(!id),
    });
    return {
        checkCanPauseData:
            checkCanPauseData?.checkIsCanPauseSubscription || false,
        isLoadingCheckCanPauseSubscription,
    };
};

export const useGetDataAvironsubscription = (id: string) => {
    const {
        data,
        loading: isLoadingSubscription,
        error,
    } = useGetAvironSubscriptionQuery({
        variables: {
            id,
        },
    });

    const isError = !!error || Boolean(!data?.avironSubscription);
    return {
        subscriptionData: data,
        isLoadingSubscription,
        error: isError,
    };
};
