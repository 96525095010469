import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Paper } from "@mui/material";
import styles from "./RegisterBetaAccess.module.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useCheckBetaIsLiveQuery } from "services/webServer";

interface RegisterBetaAccessProps {
    handleSetSteps: (value: number) => void;
    dataBetaRequest: any;
    isJoined: boolean;
}

const statusBadge = {
    rejected: {
        title: "UNREGISTERED",
        backgroundColor: "#939393",
        color: "#fff",
        description:
            "Get early access and experience our newest features by applying to our BETA program.",
    },
    unregister: {
        title: "UNREGISTERED",
        backgroundColor: "#939393",
        color: "#fff",
        description:
            "Get early access and experience our newest features by applying to our BETA program.",
    },
    canceled: {
        title: "UNREGISTERED",
        backgroundColor: "#939393",
        color: "#fff",
        description:
            "Get early access and experience our newest features by applying to our BETA program.",
    },
    waiting: {
        title: "PENDING",
        color: "#fff",
        backgroundColor: "#F3A72E",
        description:
            "Get early access and experience our newest features by applying to our BETA program.",
    },
    accepted: {
        title: "ACCEPTED",
        color: "#fff",
        backgroundColor: "#00BC3C",
        description:
            "You have been approved to join the BETA program. Please wait until the BETA is live",
    },
    invited: {
        title: "INVITED",
        color: "#fff",
        backgroundColor: "#2F6BFF",
        description:
            "You have been invited for BETA, please click on instructions to onboard onto the BETA.",
    },
};

const RegisterBetaAccess = (props: RegisterBetaAccessProps) => {
    const { handleSetSteps, dataBetaRequest, isJoined = false } = props;
    const { data } = useCheckBetaIsLiveQuery();
    const isLiveData = useMemo(() => {
        const { checkBetaIsLive: { isLive = false } = {} } =
            data || ({} as any);
        return isLive;
    }, [data]);

    const statusValue = useMemo(() => {
        if (
            (isJoined &&
                ["rejected", "canceled"].includes(dataBetaRequest?.status)) ||
            (isJoined && !dataBetaRequest)
        ) {
            return "invited";
        }
        return dataBetaRequest?.status || "unregister";
    }, [dataBetaRequest?.status, isJoined]);

    const [status, setStatus] = useState(statusBadge["unregister"]);

    useEffect(() => {
        if (statusValue) {
            // @ts-ignore
            setStatus(statusBadge[statusValue]);
        } else {
            setStatus(statusBadge["unregister"]);
        }
    }, [statusValue]);

    const handleClick = () => {
        switch (statusValue) {
            case "waiting":
                handleSetSteps(2);
                break;
            case "unregister":
            case "canceled":
            case "rejected":
                handleSetSteps(1);
                break;
            case "accepted":
                handleSetSteps(3);
                break;
            case "invited":
                handleSetSteps(4);
                break;

            default:
                break;
        }
    };

    const handleRenderTitle = useCallback(() => {
        if (statusValue !== "accepted" && !isJoined) {
            return "APPLY FOR BETA ACCESS";
        } else {
            if (isLiveData) {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>BETA IS LIVE</span>
                        <p
                            style={{
                                width: 12,
                                height: 12,
                                borderRadius: "50%",
                                backgroundColor: "#00BC3C",
                                marginLeft: 12,
                                marginBottom: 5,
                                marginTop: 0,
                            }}
                        ></p>
                    </div>
                );
            }
            return "BETA PROGRAM";
        }
    }, [isLiveData, statusValue]);

    const valueDescription = useMemo(() => {
        return (
            <p style={{ width: "70%" }}>
                {isLiveData && statusValue === "accepted"
                    ? "You have been approved for BETA, please click on instructions to onboard onto the BETA."
                    : status.description}
            </p>
        );
    }, [isLiveData, statusValue, status]);

    return (
        <Paper
            sx={{
                padding: { xs: "24px", md: "24px" },
                marginBottom: "20px",
                boxShadow: "none",
            }}
        >
            <div
                className={styles["register-beta-access"]}
                onClick={handleClick}
            >
                <div className={styles["register-beta-access-left"]}>
                    <h2 className={styles["register-beta-access-left__title"]}>
                        {handleRenderTitle()}
                    </h2>
                    <p className={styles["register-beta-access-left__content"]}>
                        {valueDescription}
                    </p>
                </div>
                <div className={styles["register-beta-access-right"]}>
                    <p
                        style={{
                            backgroundColor: status.backgroundColor,
                            color: status.color,
                            fontWeight: 500,
                        }}
                    >
                        {status.title}
                    </p>
                    <ArrowForwardIosIcon
                        fontSize="small"
                        style={{ color: "#666666" }}
                    />
                </div>
            </div>
        </Paper>
    );
};

export default RegisterBetaAccess;
