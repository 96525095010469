import React from 'react';
import style from './MemberLink.module.scss';
import GreaterArrow from 'Components/Icons/GreaterArrow'
import { useHistory } from 'react-router-dom';

interface MemberLinkProps {
  text?: string;
  link?: string;
}

const MemberLink = ({text="Member list", link = ""}: MemberLinkProps) => {
  const history = useHistory();
  const handleClick = () => {
    link && history.push(link);
  }
  return (
    <div className={style['MemberLink'] + ' MembershipLinkBtn'} onClick={handleClick}>
      <div className={style['text']}>{text}</div>
      <div className={style['icon']}><GreaterArrow /></div>
    </div>
  )
}

export default MemberLink;