import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";

import React from "react";
import { useLocation } from "react-router-dom";

interface ParamListConfig {
    id?: string;
    entry?: string;
}
export type QueryConfig = {
    [key in keyof ParamListConfig]: string;
};

const useQueryParams = () => {
    const { search } = useLocation();

    const value = React.useMemo(() => new URLSearchParams(search), [search]);

    const queryConfig: ParamListConfig = omitBy(
        {
            id: value.get("id"),
            entry: value.get("entry"),
        },
        isNil
    );

    return queryConfig;
};
export default useQueryParams;
