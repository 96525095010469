import { Backdrop, Box, Button, Fade, Modal, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import "./style.scss";

const useStyles: any = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const RetryChargeMembershipModal: React.FunctionComponent<{
  open: boolean,
  onClose: any,
  onSubmit: () => Promise<void>
}> = ({ open, onClose, onSubmit }) => {

  const classes = useStyles();
  const handleSubmit = (event: any) => {
    onSubmit();
  }

  return (
    <Modal open={open} onClose={onClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className="cancel-membership-modal-component">
          <Box mb={3}>
            <Typography component="h3" variant="h3" mb="8px">RETRY CHARGE MEMBERSHIP</Typography>
            <Typography>Membership will be charged immediately.</Typography>
          </Box>
          <Stack spacing={2} direction={{ xs: 'column-reverse', md: 'row' }} alignItems="end" justifyContent="flex-end" width="100%" sx={{
            float: "right"
          }}>
            <Button variant="outlined" onClick={onClose}>EXIT</Button>
            <Button variant="contained" onClick={handleSubmit} color="secondary">{false ? <i className="fa fa-circle-o-notch fa-spin"></i> : "RETRY NOW"}</Button>
          </Stack>
        </div>
      </Fade>
    </Modal>
  )
}

export default RetryChargeMembershipModal;