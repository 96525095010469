import { Box, Button, Typography } from "@mui/material";
import GoBack from "Components/GoBack";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import styles from "./CancellationOffer.module.scss";
import useWindowSize from "Hooks/useWindowSize";
import useQueryParams from "Hooks/useQueryParams";
import { useGetSubscriptionType } from "./CancellationOffer.hooks";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";
import Swal from "sweetalert2";
import { useTrackingData } from "Components/TrackingData";

enum MEMBERSHIP_INTERVAL {
    MONTHLY = "monthly",
    ANNUAL = "yearly",
}

const CancellationOffer = () => {
    const isDesktop = useWindowSize(1280).width > 768;
    const history = useHistory();
    const { id = "" } = useQueryParams();
    const { subscriptionData, isLoadingSubscription, error } =
        useGetSubscriptionType(id);
    const {
        trackingOfferPageView,
        trackingOfferRedeemClick,
        trackingOfferRejectClick,
    } = useTrackingData(id);

    const handleRenderErrorContent = () => {
        return (
            <>
                {Swal.fire("Error", "Something went wrong", "error").then(
                    (result) => {
                        if (result.isConfirmed) {
                            history.replace(
                                CONSTANTS.NAVIGATION_URL.MEMBERSHIPS
                            );
                        }
                    }
                )}
                ;
            </>
        );
    };

    useEffect(() => {
        if (subscriptionData?.avironSubscription) {
            trackingOfferPageView(subscriptionData);
        }
    }, [subscriptionData]);

    const isMonthlyMembership = useMemo(
        () =>
            subscriptionData?.avironSubscription?.plan?.interval ===
            MEMBERSHIP_INTERVAL.MONTHLY,
        [subscriptionData]
    );
    const isAnnualMembership = useMemo(
        () =>
            subscriptionData?.avironSubscription?.plan?.interval ===
            MEMBERSHIP_INTERVAL.ANNUAL,
        [subscriptionData]
    );

    if (isLoadingSubscription) return <AvironLoading />;

    if (Boolean(error)) {
        handleRenderErrorContent();
    }

    return (
        <div className={styles["cancellation-offer"]}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Membership Cancellation Offer | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <GoBack />
            <Box
                sx={{
                    padding: {
                        xs: "12px 16px",
                        sm: "40px",
                        backgroundColor: "#fff",
                        marginTop: "24px",
                    },
                }}
            >
                <div className={styles["hand-gift"]}>
                    <img src="/images/membership/hand-gift.png" alt="gift" />
                </div>
                <Typography
                    sx={{
                        fontSize: "16px",
                        lineHeight: "20px",
                        textAlign: "center",
                        fontWeight: 500,
                        marginBottom: {
                            xs: "24px",
                            md: "16px",
                        },
                    }}
                >
                    Thank you for being a valued Aviron member!
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: "32px",
                        lineHeight: "35.44px",
                        fontFamily: "GRIFTER",
                        letterSpacing: "0.05em",
                        textAlign: "center",
                        marginBottom: "16px",
                    }}
                >
                    {isMonthlyMembership && (
                        <>
                            <span className={styles["high-light-text"]}>
                                TODAY ONLY,
                            </span>
                            GET 50% OFF YOUR MONTHLY {isDesktop && <br />}{" "}
                            MEMBERSHIP FOR 2 MONTHS
                        </>
                    )}
                    {isAnnualMembership && (
                        <>
                            <span className={styles["high-light-text"]}>
                                TODAY ONLY,
                            </span>
                            GET 25% OFF YOUR ANNUAL
                            {isDesktop && <br />} MEMBERSHIP FOR A YEAR
                        </>
                    )}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "20px",
                        textAlign: "center",
                        color: "#000",
                        marginBottom: {
                            xs: "32px",
                            md: "40px",
                        },
                    }}
                >
                    Don't miss out on the fun and keep accessing your favorite
                    workouts.
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                    }}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            trackingOfferRejectClick(subscriptionData);
                            history.push(
                                `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_REVIEW_AND_CANCEL}?id=${id}&entry=offer_reject_click`
                            );
                        }}
                        sx={{
                            marginBottom: {
                                xs: "16px",
                                md: 0,
                            },
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "19.98px",
                            letterSpacing: "0.01em",
                        }}
                    >
                        NO, THANKS
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                            marginLeft: {
                                xs: "0",
                                md: "16px",
                            },
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "19.98px",
                            letterSpacing: "0.01em",
                        }}
                        onClick={() => {
                            trackingOfferRedeemClick(subscriptionData);
                            history.push(
                                `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_REDEEM_OFFER}?id=${id}`
                            );
                        }}
                    >
                        REDEEM OFFER
                    </Button>
                </Box>
            </Box>
        </div>
    );
};

export default CancellationOffer;
