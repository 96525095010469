import Close from "@mui/icons-material/Close";
import {
    Backdrop,
    Box,
    Button,
    IconButton,
    Modal,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import styles from "./MembershipRedeemOfferModal.module.scss";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});


const KlaviyoUpgradeMembershipSuccessModal: React.FunctionComponent<{
    open: boolean;
    onClose: any;
}> = ({ open, onClose }) => {

    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
            <div className={styles["membership-canceled-modal"]}>
                <IconButton
                    onClick={onClose}
                    className={styles["close-button"]}
                >
                    <Close />
                </IconButton>
                <Box>
                    <div className={styles["membership-canceled-modal-image"]}>
                        <img
                            src="/images/membership/upgrade-plan.svg"
                            alt="Upgrade plan success"
                        />
                    </div>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: {
                                xs: "20px",
                                md: "24px",
                            },
                            lineHeight: {
                                xs: "20px",
                                md: "24.48px",
                            },
                            letterSpacing: "0.01em",
                            textAlign: "center",
                            color: "#161616",
                            marginBottom: "24px",
                            fontFamily: "GRIFTER",
                        }}
                    >
                        PLAN UPGRADED
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "20px",
                            alignItems: "center",
                            textAlign: "center",
                            marginBottom: {
                                xs: "32px",
                                md: "56px",
                            },
                        }}
                    >
                        You've snagged a sweet deal! Keep the momentum going.
                        <br />We're excited to see what you'll achieve next!
                    </Typography>
                </Box>
                <Box
                    sx={{
                        textAlign: "center",
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={onClose}
                        color="secondary"
                        fullWidth
                        sx={{
                            marginBottom: "24px",
                            width: {
                                xs: "100%",
                                md: "120px",
                            },
                            margin: "auto",
                        }}
                    >
                        OK
                    </Button>
                </Box>
            </div>
        </Modal>
    );
};

export default KlaviyoUpgradeMembershipSuccessModal;
