import { ACTION_TYPES } from 'contexts/types/action-types';
import GLOBAL_FUNCTIONS from 'scripts/global-functions';
import { BaseReducer } from '../base/base.reducer';

class AuthReducer extends BaseReducer {
    constructor() {
        super("AUTH", {
            email: null,
            username: null
        }, {
            loginSuccess: ACTION_TYPES.AUTH.LOGIN_SUCCESS,
            setCrmSession: ACTION_TYPES.AUTH.SET_CRM_SESSION,
            stravaConnected: ACTION_TYPES.AUTH.STRAVA_CONNECTED,
            logbookConnected: ACTION_TYPES.AUTH.LOGBOOK_CONNECTED
        })
    }
    reducer = (
        state: any = this.initState,
        action: {
            type: string;
            payload: any;
        }
    ) => {
        switch (action.type) {
            case this.actions.loginSuccess:
                state = GLOBAL_FUNCTIONS.SetObject(state, action.payload);
                break;
            case this.actions.setCrmSession:
                state.crmToken = action.payload.token;
                state.crmTokenExpires = action.payload.expires;
                break;
            case this.actions.stravaConnected:
                state.isStravaConnected = action.payload;
                break;
            case this.actions.logbookConnected:
                state.isLogbookConnected = action.payload;
                break;
            default:
                break;
        }
        return state;
    };
}

const authReducer = new AuthReducer()

export {
    authReducer
}