import React, { ReactChildren } from "react";
import style from "./style.module.scss";
import {
    InputLabel as ComponentMui,
    InputLabelProps as ComponentMuiProps,
} from "@mui/material";

interface ComponentProps extends ComponentMuiProps {
    children?: any;
}
const InputLabel = (props: ComponentProps) => {
    const { children } = props;
    return (
        <div className={style["InputLabel-Wraper"]}>
            <ComponentMui {...props}>
                {children}
            </ComponentMui>
        </div>
    );
};

export default InputLabel;
