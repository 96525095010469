import React, { ReactChildren } from "react";
import style from "./InviteButton.module.scss";
import PlusIcon from "./PlusIcon";

interface InviteButtonProps {
    text?: string;
    icon?: any;
    onClick?: any;
    className?: string | any
}
const InviteButton = ({
    text = "Invite Member",
    icon = <PlusIcon />,
    onClick,
    className
}: InviteButtonProps) => {
    return (
        <button
            className={style["InviteButton"] + " inviteButton" + ` ${className}`}
            onClick={onClick}
        >
            {icon && <div className={style["icon"]}>{icon}</div>}
            {text && <div className={style["text"]}>{text}</div>}
        </button>
    );
};

export default InviteButton;
