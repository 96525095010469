import http, { BaseGameServerAPI } from "./base";
export interface IGetMembershipDetailForC2 {
    orgId: string;
    page?: number;
    perPage?: number;
    search?: string
}

export interface IInviteMembership{
    orgId: string;
    email: string;
}


interface IDeleteMembership {
    orgId: string,
    deleteField?: string,
    deleteValue: Array<string>,
}

interface IConfirmInviteMembership {
    orgId: string,
    status?: number,
    userId: string,
}

interface IGetMembershipActivated {
    orgId: string;
    page?: number;
    perPage?: number;
    search?: string
}

interface IGetListMemberInvite {
    orgId: string,
    search: string,
    pagination?: {
        page: number,
        perPage: number
    }
}

export class MembershipAPI extends BaseGameServerAPI {
    constructor() {
        super();
    }

    public async getMembershipHistoriesListForC2() {
        return await http.post('/get_memberships')
    }

    public async getPlanListForC2() {
        return await http.post('/get_pricing_plans', {
            platformType: 4,
        })
    }

    public async getMembershipDetailForC2({
        orgId,
        page,
        perPage,
    }: IGetMembershipDetailForC2) {
        return await http.post('/get_membership_detail', {
            orgId,
            pagination: {
                page,
                perPage,
            },
        })
    }

    public async getMembershipFamilly({
        orgId,
        search,
    }: IGetMembershipDetailForC2) {
        try {
            return await http.post('/get_membership_detail', {
                orgId,
                search,
                pagination: {
                    page: 1,
                    perPage: 50,
                },
            })   
        } catch (error) {
            throw error
        }
    }

    public async getMembershipActivated({
        orgId,
        search,
    }: IGetMembershipActivated) {
        try {
            return await http.post('/get_membership_detail', {
                orgId,
                search,
                pagination: {
                    page: 1,
                    perPage: 50,
                },
            })   
        } catch (error) {
            throw error
        }
    }

    public async getMembershipInviteList({
        orgId,
        search,
        pagination
    }: IGetListMemberInvite) {
        try {
            const res =  await http.post('/get_list_member_invite', {
                orgId,
                search,
                pagination,
            })
            return res;   
        } catch (error) {
            throw error
        }
    }

    public async inviteMembership({
        orgId,
        email,
    }: IInviteMembership) {
        try {
            const res =  await http.post('/invite_member_join_membership', {
                orgId,
                email,
            })
            return res;   
        } catch (error) {
            throw error
        }
    }
    
    public async deleteMembership({
        orgId,
        deleteField,
        deleteValue,
    }: IDeleteMembership) {
        try {
            const res =  await http.post('/delete_member_out_membership', {
                orgId,
                deleteField: "user_id",
                deleteValue,
            })
            return res;   
        } catch (error) {
            throw error
        }
    }

    public async confirmInviteMembership({
        orgId,
        userId,
        status=1,
    }: IConfirmInviteMembership) {
        try {
            const res =  await http.post('/confirm_invite_join_membership', {
                orgId,
                userId,
                status,
            })
            return res;   
        } catch (error) {
            throw error
        }
    }
}
