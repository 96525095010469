import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React from "react";
import style from "./ConfirmSetPasswordModal.module.scss";
import Button from "@mui/material/Button";
import CircleLockIcon from 'Components/Icons/CircleLock'

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const ConfirmSetPasswordModal = (props: any) => {
    const { open, onClose, message="To update your email, you must first create a password.", onConfirm } = props;
    const classes = useStyles();

    const handleClickOk = async () => {
      await onConfirm();
    };

    const handleClickCancel = () => {
      onClose();
  };

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal + " " + style["modalCustom"]}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 800,
            }}
        >
            <Fade in={open}>
                <div className={style["change-password-modal"]}>
                    <div className={style["close-btn"]} onClick={onClose}>
                        <img
                            src="/images/icons/x-icon.svg"
                            width={18}
                            height={18}
                            alt="icon"
                        />
                    </div>
                    <Box>
                        <div className={style['successIcon']}>
                            <CircleLockIcon />
                        </div>
                        <div className={style['title']}>
                            PASSWORD NEEDED
                        </div>
                        <div className={style['text']}>
                            {message}
                        </div>
                        <div className={style["buttons-group"]}>
                          <div className={style['okButton']}>
                            <Button
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                  sx={{
                                      height: "64px",
                                  }}
                                  onClick={handleClickOk}
                              >
                                OK
                            </Button>
                          </div>
                            <div className={style['cancelButton']} onClick={handleClickCancel}>
                                <div className={style['cancelText']}>
                                    CANCEL
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};

export default ConfirmSetPasswordModal;
