import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Field from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import style from "./ChangeEmailModal.module.scss";
import Button from "@mui/material/Button";
import SendEmailSuccessModal from "../SendEmailSuccessModal";
import { useSendConfirmNewEmailMutation } from "services/webServer";
import {formSchema} from './formSchemal'
import { AvironLoading } from "Components/Icons/AvironLoading";
import InputPassword from 'Components/base/InputPassword'

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const ChangeEmailModal = (props: any) => {
    const { open, onClose, oldEmail, auth } = props;
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const classes = useStyles();
    const [sendConfirmNewEmail] = useSendConfirmNewEmailMutation();
    const [ showAvironLoading, setShowAvironLoading ] = useState(false);

    const initialValues = {
        newEmail: "",
        currentPassword: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema,
        validate: (values)=>{
            if(values.newEmail === oldEmail) {
                // console.log({values, oldEmail})
                return {
                    newEmail: 'Another account is already associated with this email address.'
                }
            }
        },
        onSubmit: async (values) => {
            handleSendNewEmail(values);
        },
    });

    const handleSendNewEmail = async (values: any) => {
        try {
            setShowAvironLoading(true)
            sendConfirmNewEmail({variables:{
                input: {
                    oldEmail: oldEmail,
                    newEmail: values.newEmail,
                    userId: auth?.id,
                    oldPassword: values.currentPassword
                }
            }}).then((res: any) => {
                setShowAvironLoading(false)
                if(res?.data?.sendConfirmNewEmail.code==='SENT_EMAIL') {
                    onClose();
                    setOpenSuccessModal(true);
                } else if(res?.data?.sendConfirmNewEmail.code==='EMAIL_EXISTED') {
                    formik.setFieldError('newEmail', 'Another account is already associated with this email address.') 
                } else if(res?.data?.sendConfirmNewEmail.code==='PASSWORD_INVALID') {
                    formik.setFieldError('currentPassword', 'Current Password is incorrect') 
                } else {

                }
            })
        } catch (error) {
            console.log(error)
            setShowAvironLoading(false)
        }
    }

    const handleSubmitForm = () => {
        formik.handleSubmit();
    };

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal + " " + style["modalCustom"]}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 800,
                }}
            >
                <Fade in={open}>
                    <div className={style["change-password-modal"]}>
                        <div className={style["close-btn"]} onClick={onClose}>
                            <img
                                src="/images/icons/x-icon.svg"
                                width={18}
                                height={18}
                                alt="icon"
                            />
                        </div>

                        <div className={style["titleModal"]}>UPDATE EMAIL</div>
                        <Box>
                            <form onSubmit={formik.handleSubmit}>
                                <div className={style["text-field-group"]}>
                                    <Field
                                        name="newEmail"
                                        label="New Email"
                                        variant="outlined"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        value={formik.values.newEmail}
                                    />
                                    {formik.errors.newEmail && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "12px",
                                                marginTop: 8,
                                            }}
                                        >
                                            {formik.errors.newEmail}
                                        </div>
                                    )}
                                </div>

                                <div className={style["text-field-group"]}>
                                    <InputPassword
                                        name="currentPassword"
                                        label="Current Password"
                                        placeholder="Please confirm your current password"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        value={formik.values.currentPassword}
                                    />
                                    {formik.errors.currentPassword && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "12px",
                                                marginTop: 8,
                                            }}
                                        >
                                            {formik.errors.currentPassword}
                                        </div>
                                    )}
                                </div>

                                <div className={style["buttons-group"]}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            height: "64px",
                                        }}
                                    >
                                        UPDATE EMAIL
                                    </Button>
                                </div>
                            </form>
                        </Box>
                    </div>
                </Fade>
            </Modal>
            <SendEmailSuccessModal
                open={openSuccessModal}
                onClose={() => {
                    setOpenSuccessModal(false);
                    formik.resetForm();
                }}
                oldEmail={oldEmail}
            />
            {showAvironLoading && <AvironLoading />}
        </>
    );
};

export default ChangeEmailModal;
