import { WorkoutHistoryAPI } from "./workoutHistory";
import { UserAPI } from "./user";
import { AppAPI } from "./app";
import { MembershipAPI } from "./common/membership";
import { MachineAPI } from './common/machine'

class GameServerApi {
    constructor() {
        this.workoutHistory = new WorkoutHistoryAPI();
        this.user = new UserAPI();
        this.app = new AppAPI();
        this.membership = new MembershipAPI();
        this.machine = new MachineAPI()
    }
    workoutHistory: WorkoutHistoryAPI;
    user: UserAPI;
    app: AppAPI;
    membership: MembershipAPI;
    machine: MachineAPI;
}

export const gameServerApi = new GameServerApi();
