import React from "react";
import style from "./InputPassword.module.scss";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const InputPassword = (props: any) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    return (
        <div className={style["InputPassword"]}>
            <TextField {...props}
              type={showPassword ? 'text' : 'password'}
            />
            <div
                className={style["iconPassword"]}
                onClick={handleClickShowPassword}
                onMouseDown={handleClickShowPassword}
            >
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </div>
        </div>
    );
};

export default InputPassword;
