import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './ChangeEmailSuccessPage.module.scss';
import TickSuccess from 'Components/Icons/TickSuccess'
import { Button } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { CONSTANTS } from 'consts';
import { UserAppContext } from 'contexts';
import { useLogoutMutation, useUserChangeEmailMutation } from 'services/webServer';
import { getUrlVars } from 'utils/url';
import TickError from 'Components/Icons/TickError';
import { AvironLoading } from 'Components/Icons/AvironLoading';
import ChangeEmailSuccessModal from './ChangeEmailSuccessModal'

const ChangeEmailSuccessPage = () => {
  const history = useHistory();
//   const location = useLocation();
//   const search = location.search;
  const params = getUrlVars();
  const [stateUpdate, setStateUpdate] = useState<"init" | "success" | "error">(
      'init'
  );
  const [errorMsg, setErrorMsg] = useState('');
  const [changeUserEmail] = useUserChangeEmailMutation();
  const appId = localStorage.getItem("appId");
  const { auth } = useContext(UserAppContext);
  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    await logout();
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("appId", appId || "");
    window.location.href = `${window.location.origin}/login`;
  };


  function usePreviousValue(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
  }

  const prevAuth: any = usePreviousValue(auth);

  useEffect(() => {
      if (auth?.email && params?.code) {
         if(auth?.email !== prevAuth?.email) {
            changeUserEmail({
                variables: {
                    emailToken: params.code,
                },
            })
                .then((res) => {
                    if (res.data?.userChangeEmail?.error) {
                        setStateUpdate("error");
                        setErrorMsg(res.data?.userChangeEmail?.error);
                    } else {
                        setStateUpdate("success");
                    }
                })
                .catch((err) => {
                    setStateUpdate("error");
                    setErrorMsg("FAILED TO UPDATE YOUR EMAIL");
                });  
         }
      }
  }, [auth]);

  useEffect(()=>{
    if(stateUpdate === 'success') {
        document.body.style.overflow = "hidden";
    }
  }, [stateUpdate])

  return (
      <div className={style["ChangeEmailConfirmPage"]}>
          {stateUpdate === "success" && (
              <div className={style["ChangeEmailSuccessPage"]}>
                  <div>
                      <TickSuccess />
                      <div className={style["title"]}>
                          YOUR EMAIL CHANGE SUCCESSFULLY
                      </div>
                      <div className={style["buttonGroup"]}>
                          <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                document.body.style.overflow = "";
                                handleLogout();
                              }}
                          >
                              OK
                          </Button>
                      </div>
                  </div>
              </div>
          )}
          {stateUpdate === "init" && (
              <div className={style["ChangeEmailLoadingPage"]}>
                  <AvironLoading />
              </div>
          )}
          {stateUpdate === "error" && (
              <div className={style["ChangeEmailErrorPage"]}>
                  <TickError />
                  <div className={style["title"]}>{errorMsg}</div>
                  <div className={style["buttonGroup"]}>
                      <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                              history.push(CONSTANTS.NAVIGATION_URL.PROFILE);
                          }}
                      >
                          TRY AGAIN
                      </Button>
                  </div>
              </div>
          )}
      </div>
  );
}

export default ChangeEmailSuccessPage;