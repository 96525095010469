import { CONSTANTS } from "consts";
import React from "react";

export interface IMachineData {
    machineId: string,
    appId: number,
}

export interface IMachine {
    machineId: string,
    groupName: string,
    machineName: string,
    appId: number,
}

const getMachineInfoByAppId = (appId: number) => {
    if(appId === CONSTANTS.APP.AVIRON_BIKE) {
        return {
            groupName: 'Aviron',
            machineName: 'Bike',
            icon: 'https://cdn.rowaviron.com/app/apk.png'
        }
    } else if(appId === CONSTANTS.APP.AVIRON_APK) {
        return {
            groupName: 'Aviron',
            machineName: 'Rower',
            icon: 'https://cdn.rowaviron.com/app/apk.png'
        }
    } else if(appId === CONSTANTS.APP.AVIRON_TREADMILL) {
        return {
            groupName: 'Aviron',
            machineName: 'Treadmill',
            icon: 'https://cdn.rowaviron.com/app/apk.png'
        }
    }
}
export const formatMachineData = (machines: Array<IMachineData>) => {
    let res: Array<IMachine> = [];
    if(machines) {
        for (let i = 0; i < machines.length; i ++) {
            const item = machines[i];
            const machineInfo = getMachineInfoByAppId(item?.appId)
            res.push({
                machineId: item?.machineId,
                groupName: machineInfo?.groupName || '',
                machineName: machineInfo?.machineName || '',
                appId: item?.appId,
            })
        }
    }
    return res;
}
