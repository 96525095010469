import React from "react";
const TickError = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
        >
            <path
                d="M33.9998 67.3333C15.5898 67.3333 0.666504 52.41 0.666504 34C0.666504 15.59 15.5898 0.666626 33.9998 0.666626C52.4098 0.666626 67.3332 15.59 67.3332 34C67.3332 52.41 52.4098 67.3333 33.9998 67.3333ZM33.9998 29.2866L24.5732 19.8566L19.8565 24.5733L29.2865 34L19.8565 43.4266L24.5732 48.1433L33.9998 38.7133L43.4265 48.1433L48.1432 43.4266L38.7132 34L48.1432 24.5733L43.4265 19.8566L33.9998 29.2866Z"
                fill="#E60A32"
            />
        </svg>
    );
};

export default TickError;
