import style from "./Pagination.module.scss";
import React from "react";
import PaginationMui from "@mui/material/Pagination";

const Pagination = (props: any) => {
    return (
        <div className={style["Pagination"]}>
            <PaginationMui variant="outlined" shape="rounded" {...props} />
        </div>
    );
};

export default Pagination;
