import React from "react";
import { Typography } from "@mui/material";
import { useCheckIsCanPauseSubscriptionQuery, useGetAvironSubscriptionQuery } from "services/webServer";
import TextFieldCountCharacter from "Components/TextFieldCount";
import { OpenZendeskChat } from "configs/Zendesk.configs";

export const useGetSubscriptionData = (id: string) => {
    const {
        data,
        loading: isLoadingSubscription,
        error,
    } = useGetAvironSubscriptionQuery({
        variables: {
            id,
        },
    });
    const isError = !!error || Boolean(!data?.avironSubscription);

    return {
        subscriptionData: data,
        isLoadingSubscription,
        error: isError,
    };
};

const MAX_LENGTH_REASON = 255;

export const useGetOptionListCancelReasons = ({
    setOpenPauseModal,
    isSubmitted,
    reason,
    setReason,
}: {
    setOpenPauseModal: (value: boolean) => void;
    isSubmitted: boolean;
    reason: string;
    setReason: (value: string) => void;
}) => {
    const handleOpenZendesk = () => {
        OpenZendeskChat();
    };

    const data = [
        {
            id: 1,
            title: "Too expensive",
            value: "too-expensive",
            isHasBackground: true,
            optionList: [
                {
                    id: 1,
                    title: (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: {
                                    xs: "16px",
                                    sm: "14px",
                                },
                                lineHeight: {
                                    xs: "20px",
                                    sm: "16px",
                                },
                            }}
                        >
                            <span
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={() => setOpenPauseModal(true)}
                            >
                                Save by pausing
                            </span>{" "}
                            your membership.
                        </Typography>
                    ),
                    isHideWithAnnualSubscription: true,
                },
                {
                    id: 2,
                    title: (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: {
                                    xs: "16px",
                                    sm: "14px",
                                },
                                lineHeight: {
                                    xs: "20px",
                                    sm: "16px",
                                },
                            }}
                        >
                            <span
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={() => {
                                    window.open(
                                        `${process.env.REACT_APP_STOREFRONT_DOMAIN}/membership`,
                                        "_blank"
                                    );
                                }}
                            >
                                Save by sharing
                            </span>{" "}
                            your membership with family.
                        </Typography>
                    ),
                    isHideWithAnnualSubscription: false,
                },
            ],
        },
        {
            id: 2,
            title: "Not exercising in general",
            value: "not-exercising-in-general",
            isHasBackground: true,
            optionList: [
                {
                    id: 3,
                    title: (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: {
                                    xs: "16px",
                                    sm: "14px",
                                },
                                lineHeight: {
                                    xs: "20px",
                                    sm: "16px",
                                },
                            }}
                        >
                            <span
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={() => setOpenPauseModal(true)}
                            >
                                Pause your membership
                            </span>{" "}
                            to take a break and come back when you're ready.
                        </Typography>
                    ),
                    isHideWithAnnualSubscription: true,
                },
                {
                    id: 4,
                    title: (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: {
                                    xs: "16px",
                                    sm: "14px",
                                },
                                lineHeight: {
                                    xs: "20px",
                                    sm: "16px",
                                },
                            }}
                        >
                            Explore{" "}
                            <span
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={() => {
                                    window.open(
                                        `${process.env.REACT_APP_STOREFRONT_DOMAIN}/workouts`,
                                        "_blank"
                                    );
                                }}
                            >
                                Aviron's immersive workouts
                            </span>
                            —you might discover something new that keeps you
                            committed.
                        </Typography>
                    ),
                    isHideWithAnnualSubscription: false,
                },
                {
                    id: 5,
                    title: (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: {
                                    xs: "16px",
                                    sm: "14px",
                                },
                                lineHeight: {
                                    xs: "20px",
                                    sm: "16px",
                                },
                            }}
                        >
                            Join our{" "}
                            <span
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={() => {
                                    window.open(
                                        `https://www.facebook.com/groups/avironactive`,
                                        "_blank"
                                    );
                                }}
                            >
                                Facebook Community
                            </span> {" "}
                            to connect with others, share motivation, and keep
                            each other accountable.
                        </Typography>
                    ),
                    isHideWithAnnualSubscription: false,
                },
            ],
        },
        {
            id: 3,
            title: "Don’t like the product",
            value: "dont-like-the-product",
            isHasBackground: false,
            isHasInput: true,
            optionList: [
                {
                    id: 6,
                    title: (
                        <>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: {
                                        xs: "16px",
                                        sm: "14px",
                                    },
                                    lineHeight: {
                                        xs: "20px",
                                        sm: "16px",
                                    },
                                    marginBottom: "24px",
                                }}
                            >
                                We’d love to hear how we can improve your
                                experience.
                            </Typography>
                            <TextFieldCountCharacter
                                isError={isSubmitted && Boolean(!reason)}
                                onChange={(e) => setReason(e.target.value)}
                                errorMessage="Please type in your feedback."
                                maxLength={MAX_LENGTH_REASON}
                                value={reason}
                                placeholder="Type your feedback here"
                            />
                        </>
                    ),
                    isHideWithAnnualSubscription: false,
                },
            ],
        },
        {
            id: 4,
            title: "Taking a temporary break due to seasonality, travel, or moving",
            value: "taking-a-break-due-to-seasonality-travel-or-moving",
            isHasBackground: true,
            optionList: [
                {
                    id: 7,
                    title: (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: {
                                    xs: "16px",
                                    sm: "14px",
                                },
                                lineHeight: {
                                    xs: "20px",
                                    sm: "16px",
                                },
                            }}
                        >
                            <span
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={() => setOpenPauseModal(true)}
                            >
                                Pause your membership
                            </span>{" "}
                            and come back when you're ready.
                        </Typography>
                    ),
                    isHideWithAnnualSubscription: true,
                },
            ],
        },
        {
            id: 5,
            title: "Illness, injury, or life event",
            value: "illness-injury-life-event",
            isHasBackground: true,
            optionList: [
                {
                    id: 7,
                    title: (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: {
                                    xs: "16px",
                                    sm: "14px",
                                },
                                lineHeight: {
                                    xs: "20px",
                                    sm: "16px",
                                },
                            }}
                        >
                            <span
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={() => setOpenPauseModal(true)}
                            >
                                Pause your membership
                            </span>{" "}
                            and come back when you're ready.
                        </Typography>
                    ),
                    isHideWithAnnualSubscription: true,
                },
            ],
        },
        {
            id: 6,
            title: "Pregnancy",
            value: "pregnancy",
            isHasBackground: true,
            optionList: [
                {
                    id: 7,
                    title: (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: {
                                    xs: "16px",
                                    sm: "14px",
                                },
                                lineHeight: {
                                    xs: "20px",
                                    sm: "16px",
                                },
                            }}
                        >
                            <span
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={() => setOpenPauseModal(true)}
                            >
                                Pause your membership
                            </span>{" "}
                            and come back when you're ready.
                        </Typography>
                    ),
                    isHideWithAnnualSubscription: true,
                },
            ],
        },
        {
            id: 7,
            title: "Technical issues",
            value: "technical-issues",
            options: [8, 10],
            isHasBackground: true,
            optionList: [
                {
                    id: 8,
                    title: (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: {
                                    xs: "16px",
                                    sm: "14px",
                                },
                                lineHeight: {
                                    xs: "20px",
                                    sm: "16px",
                                },
                            }}
                        >
                            We're sorry you've encountered issues.
                            <span
                                style={{
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={handleOpenZendesk}
                            >
                                Contact us
                            </span>
                            —we’ll work to resolve your problem as quickly as
                            possible.
                        </Typography>
                    ),
                    isHideWithAnnualSubscription: false,
                },
            ],
        },
        {
            id: 8,
            title: "Other",
            value: "other",
            isHasBackground: false,
            isHasInput: true,
            optionList: [
                {
                    id: 9,
                    title: (
                        <>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: {
                                        xs: "16px",
                                        sm: "14px",
                                    },
                                    lineHeight: {
                                        xs: "20px",
                                        sm: "16px",
                                    },
                                    marginBottom: "24px",
                                }}
                            >
                                We’d like to help address your concern. Have you
                                tried reaching out to our{" "}
                                <span
                                    style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    }}
                                    onClick={handleOpenZendesk}
                                >
                                    Customer Experience team
                                </span>
                                ? If not, we’d appreciate any specific feedback
                                about your experience.
                            </Typography>

                            <TextFieldCountCharacter
                                isError={isSubmitted && Boolean(!reason)}
                                onChange={(e) => setReason(e.target.value)}
                                errorMessage="Please type in your feedback."
                                maxLength={MAX_LENGTH_REASON}
                                value={reason}
                                placeholder="Type your feedback here"
                            />
                        </>
                    ),
                    isHideWithAnnualSubscription: false,
                },
            ],
        },
    ];
    return { data };
};

export const useCheckCanPauseMembership = (id: string) => {
    const {
        data: checkCanPauseData,
        loading: isLoadingCheckCanPauseSubscription,
    } = useCheckIsCanPauseSubscriptionQuery({
        variables: {
            subscriptionId: id || "",
        },
        skip: Boolean(!id),
    });
    return {
        checkCanPauseData:
            checkCanPauseData?.checkIsCanPauseSubscription || false,
        isLoadingCheckCanPauseSubscription,
    };
};
