import React from 'react'
const Pencil2 = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M10.75 5.71501L14.285 9.25084L6.035 17.5H2.5V13.9642L10.75 5.71417V5.71501ZM11.9283 4.53667L13.6958 2.76834C13.8521 2.61212 14.064 2.52435 14.285 2.52435C14.506 2.52435 14.7179 2.61212 14.8742 2.76834L17.2317 5.12584C17.3879 5.28211 17.4757 5.49404 17.4757 5.71501C17.4757 5.93598 17.3879 6.1479 17.2317 6.30418L15.4633 8.07167L11.9283 4.53667Z"
                fill="#FF4106"
            />
        </svg>
    );
};

export default Pencil2