import { Box, TextField, TextFieldProps, Typography } from "@mui/material";
import React from "react";

interface ITextFieldCountCharacterProps extends Omit<TextFieldProps, ""> {
    isError?: boolean;
    onChange: (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    errorMessage?: string;
    maxLength?: number;
    value: string;
    rows?: number;
}

const TextFieldCountCharacter = ({
    isError,
    onChange,
    errorMessage,
    maxLength = 100,
    value = "",
    rows = 2,
    ...rest
}: ITextFieldCountCharacterProps) => {
    const isMaxLength = value.length > maxLength;
    return (
        <>
            <Box>
                <TextField
                    {...rest}
                    value={value}
                    multiline
                    rows={rows}
                    sx={{
                        width: "100%",
                        "&:hover fieldset": {
                            borderColor: "#ccc !important",
                        },
                        "& label.Mui-focused": { color: "#b8b8b8" },
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                borderColor: "#b8b8b8 !important",
                            },
                        },
                    }}
                    error={isError || isMaxLength}
                    onChange={(e) => {
                        onChange(e);
                    }}
                />
                {!isError && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: isMaxLength
                                ? "space-between"
                                : "end",
                        }}
                    >
                        {isMaxLength && (
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: "#f5490f",
                                }}
                            >
                                Maximum character limit reached
                            </Typography>
                        )}
                        <Typography
                            sx={{
                                textAlign: "right",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "16px",
                                color:isMaxLength ? "red" : "#808080",
                            }}
                        >
                            {(value as string)?.length} / {maxLength}
                        </Typography>
                    </Box>
                )}
            </Box>
            {isError && (
                <Typography
                    sx={{
                        fontSize: "12px",
                        color: "#f5490f",
                        fontStyle: "italic"
                    }}
                >
                    {errorMessage}
                </Typography>
            )}
        </>
    );
};

export default TextFieldCountCharacter;
