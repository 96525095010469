import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PageTitle from 'Components/PageTitle';
import get from 'lodash/get';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useRetriveCustomerCurrencyQuery, useSharedQuery, useStripeUpdateCustomerDefaultPaymentMethodMutation } from 'services/webServer';
import Swal from 'sweetalert2';

import { Grid } from '@mui/material';
import loadable from '@react-loadable/revised';
import { AvironLoading } from 'Components/Icons/AvironLoading';
import { CONSTANTS } from 'consts';
import { useCheckUserHaveFinanceSubscriptionMutation } from "services/webServer";
import useHandleActivePage from 'utils/useHandleActivePage';

import moment from "moment";

const StripePaymentInfo = loadable({
    loader() {
        return import('Components/StripePaymentInfo')
    },
    loading: AvironLoading,
})

export default function PaymentMethodMain() {
    useHandleActivePage(CONSTANTS.SIDEBAR_PAGEID.PAYMENT_METHOD)
    const history = useHistory();

    const [updateCustomerDefaultPaymentMethod] =
        useStripeUpdateCustomerDefaultPaymentMethodMutation();

    const [checkUserHaveFinanceSubscription] = useCheckUserHaveFinanceSubscriptionMutation();

    const { data: customerCurrencyData } = useRetriveCustomerCurrencyQuery();
    const { data } = useSharedQuery();

    const checkFinance = async (paymentMethod: any) => {
        const { data: checkData } = await checkUserHaveFinanceSubscription({
            variables: {
                userId: data?.currentUser?.id
            }
        })
        if (checkData && checkData.checkUserHaveFinanceSubscription) {
            if (paymentMethod.card.funding !== "credit") {
                Swal.fire("Credit Card Required", "To complete your purchase, please use a credit card. Debit cards are not accepted for this transaction.", "error")
                return false
            } else {
                const next12Month = moment().add(13, "month");
                const cartExpiryDate = moment(`${paymentMethod.card.exp_month}/01/${paymentMethod.card.exp_year}`)
                if (next12Month.isAfter(cartExpiryDate)) {
                    Swal.fire("Card Expiration Date Issue", "To complete your purchase, please use a credit card with an expiration date that is at least 12 months from today. This ensures your membership remains active for the full 12-month commitment period. ", "error")
                    return false
                }
            }
        }
        return true
    }

    const handleSubmit = async (paymentMethod: any, currency: "usd" | "cad", stripeCustomerId: string) => {

        if (!await checkFinance(paymentMethod)) {
            return;
        }

        try {
            await updateCustomerDefaultPaymentMethod({
                variables: {
                    userId: data?.currentUser?.id,
                    customerId: stripeCustomerId,
                    paymentMethod: paymentMethod,
                    currency
                },
            });
            await Swal.fire("Success", "Credit card changed successfully.", "success");
            history.go(0);
        } catch (err) {
            await Swal.fire("Error", "Change credit card error.", "error");
        }
    };

    const stripeUsCustomerId = get(data, "currentUser.profile.stripe.customer_id");
    const stripeCaCustomerId = get(data, "currentUser.profile.stripe.customer_ca_id");

    return (
        <div className='page-content'>

            <Box padding={{
                xs: "0 32px",
                sm: "0"
            }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Payment method | Aviron</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <Box sx={{
                    marginBottom: "40px",
                }}>
                    <PageTitle>Payment method</PageTitle>
                </Box>
                <Box>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" component="h4" mb={"16px"}>USD account</Typography>
                            <StripePaymentInfo
                                onSubmit={handleSubmit}
                                customerId={stripeUsCustomerId}
                                currency={"usd"}
                            />
                            {/* {customerCurrencyData?.retriveCustomerCurrency?.map((currency: string) => {
                            return <StripePaymentInfo
                                onSubmit={handleSubmit}
                                customerId={stripeCustomerId}
                                currency={currency as "usd" | "cad"}
                            />
                        })} */}
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Typography variant="h4" component="h4" mb={"16px"}>CAD account</Typography>
                            <StripePaymentInfo
                                onSubmit={handleSubmit}
                                customerId={stripeCaCustomerId}
                                currency={"cad"}
                            />
                        </Grid>
                    </Grid>

                </Box>


            </Box>
        </div>


    )
}