import useQueryParams from "Hooks/useQueryParams";
import { CONSTANTS } from "consts";
import React, { useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useGetSubscriptionData } from "./RedeemOffer.hooks";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { Box, Button, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import GoBack from "Components/GoBack";
import {
    GetAvironSubscriptionQuery,
    useClaimCancelOfferSubscriptionMutation,
} from "services/webServer";
import MembershipRedeemOfferModal, {
    IMembershipRedeemOfferModalRef,
} from "./MembershipRedeemOfferModal";
import moment from "moment";
import { useTrackingData } from "Components/TrackingData";
import { vwo } from "tracking/vwo";

enum SUBSCRIPTION_TYPE {
    MONTHLY = "monthly",
    ANNUAL = "yearly",
}
enum SUBSCRIPTION_TYPE_TRACKING {
    MONTHLY = "monthly",
    ANNUAL = "annual",
}

const RedeemOffer = () => {
    const membershipRedeemOfferSuccesRef =
        useRef<IMembershipRedeemOfferModalRef>(null);
    const history = useHistory();
    const { id = "" } = useQueryParams();

    const { subscriptionData, isLoadingSubscription, error } =
        useGetSubscriptionData(id);
    const [claimCancelOffer] = useClaimCancelOfferSubscriptionMutation({
        onError: async (error) => {
            history.replace(CONSTANTS.NAVIGATION_URL.MEMBERSHIPS);
        },
        onCompleted: () => {
            const subscriptionType =
                subscriptionData?.avironSubscription?.plan?.interval ===
                SUBSCRIPTION_TYPE_TRACKING.MONTHLY
                    ? SUBSCRIPTION_TYPE_TRACKING.MONTHLY
                    : SUBSCRIPTION_TYPE_TRACKING.ANNUAL;
            vwo.trackingOfferClaimed({
                type: subscriptionType,
            });
            membershipRedeemOfferSuccesRef.current?.setIsVisible(true);
        },
    });
    const { trackngOfferConfirmScreenView } = useTrackingData(id);

    const handleRenderErrorContent = () => {
        return (
            <>
                {Swal.fire("Error", "Something went wrong", "error").then(
                    (result) => {
                        if (result.isConfirmed) {
                            history.replace(
                                CONSTANTS.NAVIGATION_URL.MEMBERSHIPS
                            );
                        }
                    }
                )}
                ;
            </>
        );
    };

    const handleRedeemOffer = async () => {
        if (!id) return;
        Swal.showLoading();
        await claimCancelOffer({
            variables: {
                id,
            },
        });
        Swal.close();
    };

    const isMonthlySubscription = useMemo(
        () =>
            subscriptionData?.avironSubscription?.plan?.interval ===
            SUBSCRIPTION_TYPE.MONTHLY,
        [subscriptionData]
    );

    const discountPriceSubscription = useMemo(() => {
        switch (subscriptionData?.avironSubscription?.plan?.interval) {
            case "monthly":
                // Discount 50% for 2 months with Monthly Membership
                return (
                    Number(
                        subscriptionData?.avironSubscription?.plan?.amount || 0
                    ) * 0.5
                ).toFixed(2);

            case "yearly":
                // Discount 25% for a year with Annual Membership
                return (
                    Number(
                        subscriptionData?.avironSubscription?.plan?.amount || 0
                    ) * 0.75
                ).toFixed(2);

            default:
                break;
        }
    }, [subscriptionData]);

    useEffect(() => {
        if (subscriptionData) {
            trackngOfferConfirmScreenView(subscriptionData);
        }
        if (subscriptionData?.avironSubscription?.remark?.cancel_offer) {
            history.replace(`${CONSTANTS.NAVIGATION_URL.MEMBERSHIPS}/${id}`);
        }
    }, [subscriptionData]);

    if (isLoadingSubscription) return <AvironLoading />;

    if (Boolean(error)) {
        handleRenderErrorContent();
    }

    return (
        <Box
            sx={{
                marginTop: {
                    xs: "24px",
                    md: "0",
                },
                marginBottom: {
                    xs: "24px",
                    md: "0px",
                },
                paddingLeft: {
                    xs: "12px",
                    md: "0",
                },
                paddingRight: {
                    xs: "12px",
                    md: "0",
                },
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Membership Review And Cancel | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <GoBack />
            <Box
                sx={{
                    padding: {
                        xs: "16px 12px",
                        sm: "40px",
                    },
                    backgroundColor: "#fff",
                    marginTop: "24px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: {
                        xs: "60vh",
                        md: "auto",
                    },
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: {
                                xs: "18px",
                                md: "24px",
                            },
                            lineHeight: {
                                xs: "20px",
                                md: "25.08px",
                            },
                            letterSpacing: "0.01em",
                            marginBottom: "24px",
                            fontFamily: "GRIFTER",
                        }}
                    >
                        REDEEM YOUR OFFER
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: "#f4f4f4",
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "space-between",
                            padding: {
                                xs: "12px",
                                md: "24px",
                            },
                            marginBottom: {
                                xs: "24px",
                                md: "40px",
                            },
                            flexDirection: {
                                xs: "column",
                                md: "row",
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontFamily: "Work Sans",
                                fontSize: {
                                    xs: "16px",
                                    md: "20px",
                                },
                                lineHeight: {
                                    xs: "20px",
                                    md: "24px",
                                },

                                textTransform: "uppercase",
                                marginBottom: {
                                    xs: "12px",
                                    md: "0",
                                },
                            }}
                        >
                            {subscriptionData?.avironSubscription?.plan?.name}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "MonoSpec",
                                    fontWeight: 500,
                                    fontSize: {
                                        xs: "20px",
                                        md: "24px",
                                    },
                                    lineHeight: {
                                        xs: "24.98px",
                                        md: "29.98px",
                                    },
                                    color: "#808080",
                                    textDecoration: "line-through",
                                }}
                            >
                                $
                                {Number(
                                    subscriptionData?.avironSubscription?.plan
                                        ?.amount || 0
                                ).toFixed(2)}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "MonoSpec",
                                    fontWeight: 500,
                                    fontSize: {
                                        xs: "20px",
                                        md: "24px",
                                    },
                                    lineHeight: {
                                        xs: "24.98px",
                                        md: "29.98px",
                                    },
                                    color: "#161616",
                                    marginLeft: "24px",
                                }}
                            >
                                ${discountPriceSubscription || 0}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: {
                                xs: "16px",
                                md: "18px",
                            },
                            lineHeight: {
                                xs: "20px",
                                md: "21.11px",
                            },
                            color: "#161616",
                            marginBottom: {
                                xs: "0",
                                md: "40px",
                            },
                        }}
                    >
                        {isMonthlySubscription ? (
                            <>
                                Starting on{" "}
                                <span style={{ fontWeight: 500 }}>
                                    {moment(
                                        subscriptionData?.avironSubscription
                                            ?.currentPeriodEnd || new Date()
                                    )
                                        .add(1, "day")
                                        .format("MMMM Do, YYYY")}
                                </span>
                                , you'll be charged a promotional price of
                                <span style={{ fontWeight: 500 }}>
                                    {" "}
                                    ${discountPriceSubscription}
                                </span>{" "}
                                for 2 months. After that, the regular monthly
                                membership fee will apply.
                            </>
                        ) : (
                            <>
                                Starting on{" "}
                                <span
                                    style={{
                                        fontWeight: 500,
                                    }}
                                >
                                    {moment(
                                        subscriptionData?.avironSubscription
                                            ?.currentPeriodEnd || new Date()
                                    )
                                        .add(1, "day")
                                        .format("MMMM Do, YYYY")}
                                </span>
                                , you'll be charged a promotional price of{" "}
                                <span style={{ fontWeight: 500 }}>
                                    {" "}
                                    ${discountPriceSubscription}
                                </span>
                                . After that, the regular annually membership
                                fee will apply.
                            </>
                        )}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        textAlign: "end",
                    }}
                >
                    <Button
                        className="btn-claim-offer"
                        variant="contained"
                        color="secondary"
                        sx={{
                            marginLeft: {
                                xs: "0",
                                md: "16px",
                            },
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "19.98px",
                            letterSpacing: "0.01em",
                        }}
                        onClick={handleRedeemOffer}
                    >
                        REDEEM
                    </Button>
                </Box>
            </Box>
            <MembershipRedeemOfferModal
                ref={membershipRedeemOfferSuccesRef}
                data={subscriptionData as GetAvironSubscriptionQuery}
            />
        </Box>
    );
};

export default RedeemOffer;
