import React, { useEffect, useState } from "react";
import style from "./table.module.scss";
import Checkbox from "Components/base/Checkbox";
import Actions from "./Actions/Actions";
import TrashCan2 from "Components/Icons/TrashCan2";
import BlockIcon from "Components/Icons/Block";
import Pagination from "Components/base/Pagination";

interface tableProps {
    columns: Array<{ key: string; title: any; className?: string }>;
    rowsData: any;
    onChangeSelection?: any;
    actions?: Array<{
        name: string;
        action: any;
    }>;
    deleteMultiAction?: any;
    handleClickRow?: any;
    loading?: boolean;
    pagination?: {
        totalRows: number;
        rowsPerPage: number;
        handleChangePage: any;
        currentPage: number;
    };
}
interface ICheckedItem {
    checked: boolean;
    data: { selection: any };
    isBlock: boolean;
}
const TableMember = (props: tableProps) => {
    const {
        rowsData,
        columns,
        actions,
        onChangeSelection,
        deleteMultiAction,
        handleClickRow,
        loading,
        pagination,
    } = props;
    const [checkedArr, setCheckedArr] = useState<any>([]);
    const [checkedAll, setCheckedAll] = useState<boolean>(false);
    // console.log("mkmkmkmkm", checkedArr);

    const checkCheckedAll = async (arr: Array<any>) => {
        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            if (item.checked === true) {
                return true;
            }
        }
        return false;
    };

    const checkCanSelected = (arr: Array<any>) => {
        let count = 0;
        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            if (!item.isBlock) {
                count++;
            }
        }
        return count;
    };

    const getCheckedTrue = (array: any) => {
        if (array?.length > 0) {
            const result = array.filter(
                (item: any) => item.checked === true && !item.isBlock
            );
            return result;
        }
        return [];
    };

    /**
     * @param array: checkbox array in clude block_checkbox
     * @returns array not include block_checkbox
     */
    const getActualChecked = (array: any) => {
        const result = array.filter((item: any) => !item.isBlock);
        return result;
    };

    const onChangeCheckbox = async (event: any, checkedRow: any) => {
        const checked = event.target.checked;
        const id = event.target.id;
        const index = Number(id.split("row_")[1]);
        checkedRow.checked = checked;
        let res = checkedArr;
        res[index] = checkedRow;
        setCheckedArr([...res]);
        onChangeSelection && onChangeSelection(res);
        const checkedAll = await checkCheckedAll(getActualChecked(res));
        // console.log("ppppppp", checkedAll);
        setCheckedAll(checkedAll);
    };

    const onChangeAllChecked = (event: any) => {
        const checked = event.target.checked;
        let res = [];
        if (checked) {
            for (let i = 0; i < checkedArr.length; i++) {
                let item = checkedArr[i];
                item.checked = true;
                res.push(item);
            }
            setCheckedArr([...res]);
        } else {
            for (let i = 0; i < checkedArr.length; i++) {
                let item = checkedArr[i];
                item.checked = false;
                res.push(item);
            }
            setCheckedArr([...res]);
        }
        setCheckedAll(checked);
        onChangeSelection && onChangeSelection(res);
    };

    const handleChangePagination = (newPage: number) => {
        setCheckedAll(false);
        pagination && pagination.handleChangePage(newPage);
    };

    const renderBody = (
        rowsData: any,
        columns: any,
        actions?: Array<{
            name: string;
            action: any;
        }>
    ) => {
        const rowsRender = checkedArr.map(
            (checkedRow: ICheckedItem, key: number) => {
                const row = rowsData[key];
                // console.log('kkkkkkkppppp', checkedRow);
                return (
                    <tr
                        key={"tr1" + key}
                        className={`trRow ${
                            checkedRow.checked ? style["rowSelected"] : ""
                        } ${checkedRow.isBlock && style["blockRow"]}`}
                    >
                        {columns.map((col: any, keyCol: number) => {
                            if (row && row[col.key]) {
                                return (
                                    <>
                                        {col.key === "selection" ? (
                                            <td
                                                className={
                                                    style["checkBoxCell"]
                                                }
                                            >
                                                {row["selection"].isBlock ? (
                                                    <div
                                                        className={
                                                            style["blockIcon"]
                                                        }
                                                    >
                                                        <BlockIcon />
                                                    </div>
                                                ) : (
                                                    <Checkbox
                                                        onChange={(event) =>
                                                            onChangeCheckbox(
                                                                event,
                                                                checkedRow
                                                            )
                                                        }
                                                        checked={
                                                            checkedRow.checked
                                                        }
                                                        id={"row_" + key}
                                                    />
                                                )}
                                            </td>
                                        ) : (
                                            <td
                                                className={
                                                    row[col?.key]?.className
                                                }
                                                key={"td1" + keyCol}
                                                onClick={() =>
                                                    handleClickRow &&
                                                    handleClickRow(row)
                                                }
                                            >
                                                <div>
                                                    {row[col?.key]?.value}
                                                </div>
                                            </td>
                                        )}
                                    </>
                                );
                            } else {
                                return "";
                            }
                        })}
                        {actions && rowsData?.length > 0 && (
                            <td className="tdActions">
                                <Actions
                                    content={actions}
                                    selection={row?.selection}
                                    hideActions={row?.hideActionArr}
                                />
                            </td>
                        )}
                    </tr>
                );
            }
        );
        return rowsRender;
    };

    const renderTHCustom = (numberSelected: number, key: number) => {
        if (key === 1) {
            return (
                <div className={style["titleCellSelected"]}>{`${
                    numberSelected === getActualChecked(checkedArr).length
                        ? "All"
                        : numberSelected
                } Selected`}</div>
            );
        } else {
            return " ";
        }
    };

    const deleteMultiActionLocal = (selections: any) => {
        deleteMultiAction && deleteMultiAction(selections);
    };

    useEffect(() => {
        if (rowsData.length > 0) {
            let res: any = [];
            rowsData.map((item: any, index: number) => {
                res.push({
                    checked: false,
                    data: item?.selection,
                    isBlock: item?.selection?.isBlock,
                });
            });
            setCheckedArr(res);
            checkCheckedAll(getActualChecked(res)).then((checkedAll: boolean)=>{
                setCheckedAll(checkedAll);
            });
        }
    }, [rowsData]);

    return (
        <div className={style["tableWrapper"]}>
            {checkedArr.length > 0 && (
                <>
                    <table>
                        <tr className="trHeader">
                            {columns.map((col: any, key: any) => {
                                return (
                                    <>
                                        {col.key === "selection" &&
                                        !rowsData[0]?.empty &&
                                        rowsData.length > 0 ? (
                                            <th
                                                className={
                                                    style["checkboxColumn"]
                                                }
                                            >
                                                {checkCanSelected(
                                                    checkedArr
                                                ) ? (
                                                    <Checkbox
                                                        onChange={
                                                            onChangeAllChecked
                                                        }
                                                        checked={checkedAll}
                                                    />
                                                ) : (
                                                    <div
                                                        className={
                                                            style["blockIcon"]
                                                        }
                                                    >
                                                        <BlockIcon />
                                                    </div>
                                                )}
                                            </th>
                                        ) : (
                                            <>
                                                {getCheckedTrue(checkedArr)
                                                    .length > 0 ? (
                                                    <th>
                                                        {renderTHCustom(
                                                            getCheckedTrue(
                                                                checkedArr
                                                            ).length,
                                                            key
                                                        )}
                                                    </th>
                                                ) : (
                                                    <th
                                                        className={
                                                            col.className
                                                        }
                                                        key={key + "header"}
                                                    >
                                                        <div>{col.title}</div>
                                                    </th>
                                                )}
                                            </>
                                        )}
                                    </>
                                );
                            })}
                            {getCheckedTrue(checkedArr).length > 0 ? (
                                <th className={style["deleteTh"]}>
                                    <div
                                        className={
                                            style["deleteFunc"] + " deleteFunc"
                                        }
                                        onClick={() =>
                                            deleteMultiActionLocal(
                                                getCheckedTrue(checkedArr)
                                            )
                                        }
                                    >
                                        <TrashCan2 />
                                    </div>
                                </th>
                            ) : (
                                actions &&
                                rowsData?.length > 0 && (
                                    <th className={style["thActions"]}></th>
                                )
                            )}
                        </tr>
                        {checkedArr && renderBody(rowsData, columns, actions)}
                    </table>
                    {pagination &&
                        Math.ceil(
                            pagination.totalRows / pagination.rowsPerPage
                        ) > 1 && (
                            <div className={style["Pagination"]}>
                                <Pagination
                                    count={Math.ceil(
                                        pagination.totalRows /
                                            pagination.rowsPerPage
                                    )}
                                    color="secondary"
                                    page={pagination.currentPage}
                                    onChange={(event: any, newPage: number) => {
                                        handleChangePagination(newPage);
                                    }}
                                />
                            </div>
                        )}
                </>
            )}
        </div>
    );
};

export default TableMember;
