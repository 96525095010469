import React, { useEffect, useMemo, useState } from "react";
import styles from "./PauseOrCancel.module.scss";
import { Box, Button, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import GoBack from "Components/GoBack";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { CONSTANTS } from "consts";
import useQueryParams from "Hooks/useQueryParams";
import PauseMembershipModal from "Screens/Memberships/Detail/PauseMembership";
import {
    GetAvironSubscriptionQuery,
    usePausePaymentSubscriptionMutation,
} from "services/webServer";
import Swal from "sweetalert2";
import { AvironLoading } from "Components/Icons/AvironLoading";
import {
    useCheckCanPauseMembership,
    useGetDataAvironsubscription,
} from "./PauseOrCancel.hooks";
import { useTrackingData } from "Components/TrackingData";

const PauseOrCancel = () => {
    const history = useHistory();
    const { id = "" } = useQueryParams();
    const [openPauseModal, setOpenPauseModal] = useState<boolean>(false);
    const [pausePaymentSubscription] = usePausePaymentSubscriptionMutation({
        onError: async () => {
            await Swal.fire(
                "Pause membership error",
                "Pause membership failed",
                "error"
            );
            history.replace(CONSTANTS.NAVIGATION_URL.MEMBERSHIPS);
        },
        onCompleted: async () => {
            await Swal.fire(
                "Success",
                "Membership paused successfully.",
                "success"
            );
            history.replace(`${CONSTANTS.NAVIGATION_URL.MEMBERSHIPS}/${id}`);
        },
    });
    const { checkCanPauseData, isLoadingCheckCanPauseSubscription } =
        useCheckCanPauseMembership(id);
    const { subscriptionData, isLoadingSubscription, error } =
        useGetDataAvironsubscription(id);
    const {
        trackingPauseOrCancelScreenView,
        trackingPauseClick,
        trackingCancelClick,
    } = useTrackingData(id);

    const handlePauseSubscription = async ({
        resumesAt,
        reason,
        note,
    }: {
        resumesAt: string;
        reason: string;
        note: string;
    }) => {
        if (!id) return;
        try {
            setOpenPauseModal(false);
            Swal.showLoading();
            await pausePaymentSubscription({
                variables: {
                    id,
                    behavior: "void",
                    resumesAt,
                    reason,
                    note,
                },
            });
            // await Swal.fire(
            //     "Success",
            //     "Membership paused successfully.",
            //     "success"
            // );
            // history.goBack();
        } catch (error) {
            console.log("Pause Subscription ERROR", error);
            // Swal.fire(
            //     "Pause membership error",
            //     "Pause membership failed",
            //     "error"
            // );
        }
    };

    useEffect(() => {
        if (subscriptionData?.avironSubscription) {
            trackingPauseOrCancelScreenView(
                subscriptionData as GetAvironSubscriptionQuery
            );
        }
    }, [subscriptionData]);

    const isCanPauseSubscription = useMemo(
        () => checkCanPauseData,
        [checkCanPauseData]
    );

    const optionsList = [
        {
            id: 1,
            title: "PAUSE",
            description: "Pause for 1 month",
            moreDetails: [
                {
                    text: "Your membership will pause immediately, and you won’t have access to membership features until billing resumes.",
                },
                {
                    text: "You can resume your membership anytime.",
                },
                {
                    text: "Your Lifetime Warranty, if you have one, will continue without interruption.",
                },
            ],
            btn: (
                <>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setOpenPauseModal(true);
                            trackingPauseClick(subscriptionData);
                        }}
                        disabled={Boolean(!isCanPauseSubscription)}
                        sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "19.98px",
                            letterSpacing: "0.01em",
                        }}
                    >
                        PAUSE
                    </Button>
                </>
            ),
        },
        {
            id: 2,
            title: "CANCEL",
            description: "End access to your membership.",
            btn: (
                <>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            trackingCancelClick(subscriptionData);
                            history.push(
                                `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_CANCEL_REASONS}?id=${id}`
                            );
                        }}
                        sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "19.98px",
                            letterSpacing: "0.01em",
                        }}
                    >
                        CANCEL
                    </Button>
                </>
            ),
        },
    ];

    const isMonthlySubscription = useMemo(
        () =>
            subscriptionData?.avironSubscription?.plan?.interval === "monthly",
        [subscriptionData]
    );

    if (isLoadingCheckCanPauseSubscription || isLoadingSubscription) {
        return <AvironLoading />;
    }

    const handleRenderErrorContent = () => {
        return (
            <>
                {Swal.fire("Error", "Something went wrong", "error").then(
                    (result) => {
                        if (result.isConfirmed) {
                            history.replace(
                                CONSTANTS.NAVIGATION_URL.MEMBERSHIPS
                            );
                        }
                    }
                )}
                ;
            </>
        );
    };

    // NOTE: Just monthly subscription can access this page
    if (!isMonthlySubscription || Boolean(!id) || Boolean(error)) {
        handleRenderErrorContent();
    }

    return (
        <>
            <div className={styles["pause-or-cancel"]}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Membership Pause Or Cancel | Aviron</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <GoBack />

                {optionsList.map((option, idx) => (
                    <Box
                        key={idx}
                        sx={{
                            padding: {
                                xs: "16px 12px",
                                md: "40px",
                                backgroundColor: "#fff",
                                marginTop: "24px",
                            },

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: {
                                xs: "column",
                                sm: "row",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontSize: {
                                        xs: "18px",
                                        sm: "20px",
                                    },
                                    lineHeight: "20px",
                                    letterSpacing: "0.01em",
                                    marginBottom: "12px",
                                    fontFamily: "GRIFTER",
                                }}
                            >
                                {option.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                }}
                            >
                                {option.description}
                            </Typography>
                            <ul className={styles["more-detail-list"]}>
                                {option.moreDetails?.map((detail, idx) => (
                                    <li
                                        key={idx}
                                        className={classNames(
                                            styles["more-detail-item"],
                                            {
                                                [styles[
                                                    "more-detail-last-item"
                                                ]]:
                                                    idx ===
                                                    option.moreDetails.length -
                                                        1,
                                            }
                                        )}
                                    >
                                        {detail.text}
                                    </li>
                                ))}
                            </ul>
                        </Box>
                        <Box
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "auto",
                                },
                            }}
                        >
                            {option.btn}
                        </Box>
                    </Box>
                ))}
            </div>
            <PauseMembershipModal
                open={openPauseModal}
                onClose={() => setOpenPauseModal(false)}
                onSubmit={handlePauseSubscription}
            />
        </>
    );
};

export default PauseOrCancel;
