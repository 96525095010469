import { Backdrop, Box, Button, Fade, Modal, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { ISubmitPauseSubscription } from '..';
import "./style.scss";

const useStyles: any = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

// const reasonReg = /^[a-zA-Z 0-9\.\,\+\-\s]*$/;

// reason: Khong co ky tu dac biet, toi da 255 ky tu, khong duoc rong, khong duoc chi co khoang trong

const PauseMembershipModal: React.FunctionComponent<{
  open: boolean,
  onClose: any,
  onSubmit: (params: ISubmitPauseSubscription) => Promise<void>
  currentPeriodEnd?: string;
}> = ({ open, onClose, onSubmit, currentPeriodEnd }) => {
  const [formValue, setFormValue] = React.useState({
    time: 1,
    // reason: "",
    note: "",
    startAt: moment().format("MM/DD/YYYY"),
    endAt: moment().add(1, "months").format("MM/DD/YYYY")
  })

  const [formError, setFormError] = React.useState<any>({
    note: null,
    reason: null,
    time: null
  })

  React.useEffect(() => {
    setFormValue({
      time: 1,
      // reason: "",
      note: "",
      startAt: moment().format("MM/DD/YYYY"),
      endAt: moment().add(1, "months").format("MM/DD/YYYY")
    })
    setFormError({
      note: null,
      // reason: null,
      time: null
    })
  }, [open])

  const classes = useStyles();
  const handleSubmit = (event: any) => {
    event.preventDefault();
    let isError = false;
    let error: any = {};
    if (!formValue.time) {
      error.time = "Please choose time"
      isError = true;
    }
    if (!formValue.note.trim().length) {
      error.note = "Please type in your feedback."
      isError = true;
    }
    else if (formValue.note.length > 255) {
      error.note = "Please enter less than 255 letters"
      isError = true;
    }
    if (isError) {
      setFormError(error)
      return;
    }
    onSubmit({
      resumesAt: moment().add(formValue.time || "", "months").format(),
      reason: "default",
      note: formValue.note
    });
    setFormValue({
      time: 1,
      note: "",
      startAt: moment().format("MM/DD/YYYY"),
      endAt: moment().add(1, "months").format("MM/DD/YYYY")
    })
    setFormError({
      note: null,
      // reason: null,
      time: null
    })
  }

  const handleChange = (event: any) => {
    if (event.target.name === "note") {
      if (event.target.value.trim().length === 0) {
        setFormError({ ...formError, note: "Please type in your feedback." })
      }
      else if (event.target.value.length > 255) {
        setFormError({ ...formError, note: "Please enter less than 255 letters" })
      } else {
        setFormError({ ...formError, note: null })
      }
    }
    setFormValue({ ...formValue, [event.target.name]: event.target.value })
  }
  return (
    <Modal open={open} onClose={onClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>

        <div className="cancel-membership-modal-component">

          <Box>
            <Typography component="h3" variant="h3" mb="8px">PAUSE MEMBERSHIP</Typography>
            <Typography>Your membership will be paused after <b>{formValue.startAt}</b>. Membership will be automatically resumed on <b>{formValue.endAt}</b>.</Typography>
            <Typography>However, you can manually resume your membership at any time. If you have the Lifetime Warranty, this action will not impact its status.</Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box margin="20px 0 20px 0">
            </Box>
            <Box margin="20px 0 32px 0">
            <TextField variant='outlined' fullWidth name="note" size="medium" onChange={handleChange} label="Why would you like to pause?" required />
              {!!formError.note && <i className="form-error">{formError.note}</i>}
            </Box>
            <Stack spacing={2} direction={{ xs: 'column-reverse', md: 'row' }} alignItems="end" justifyContent="flex-end" width="100%" sx={{
              float: "right"
            }}>
              <Button variant="outlined" onClick={onClose}>EXIT</Button>
              <Button type="submit" variant="contained" onClick={handleSubmit} color="secondary">{false ? <i className="fa fa-circle-o-notch fa-spin"></i> : "PAUSE MEMBERSHIP"}</Button>
            </Stack>
          </form>
        </div>
      </Fade>
    </Modal>
  )
}

export default PauseMembershipModal;